import * as React from "react";

const SvgArrowSUp = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M18 15a.995.995 0 01-.64-.232l-5.371-4.476-5.362 4.315a.999.999 0 11-1.253-1.558l6-4.828a.998.998 0 011.266.011l6 5A1 1 0 0118 15"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgArrowSUp;
