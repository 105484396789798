import * as React from "react";

const SvgArrowSLeft = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M13.829 19a.998.998 0 01-.78-.373l-4.828-6a1 1 0 01.01-1.267l5-6a1 1 0 111.536 1.28l-4.474 5.371 4.315 5.362a1 1 0 01-.78 1.627"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgArrowSLeft;
