import * as React from "react";

const SvgInsights1Filled = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M37.0909 18.6952C34.9818 18.6952 33.8036 20.7281 34.2836 22.2387L29.12 27.2646C28.6836 27.1375 28.0436 27.1375 27.6073 27.2646L23.8982 23.6646C24.3927 22.154 23.2145 20.107 21.0909 20.107C18.9818 20.107 17.7891 22.1399 18.2836 23.6646L11.6509 30.0881C10.0945 29.6222 8 30.7658 8 32.8128C8 34.3658 9.30909 35.6364 10.9091 35.6364C13.0182 35.6364 14.1964 33.6034 13.7164 32.0928L20.3345 25.6552C20.7709 25.7822 21.4109 25.7822 21.8473 25.6552L25.5564 29.2552C25.0618 30.7658 26.24 32.8128 28.3636 32.8128C30.4727 32.8128 31.6655 30.7799 31.1709 29.2552L36.3491 24.2434C37.9055 24.7093 40 23.5658 40 21.5187C40 19.9658 38.6909 18.6952 37.0909 18.6952Z"
        fill="black"
      />
      <path
        d="M28.3636 20.1069L29.7309 17.1846L32.7273 15.8716L29.7309 14.5587L28.3636 11.6364L27.0255 14.5587L24 15.8716L27.0255 17.1846L28.3636 20.1069Z"
        fill="#24B2D7"
      />
      <path
        d="M11.6364 22.9305L12.3636 20.1069L15.2727 19.4011L12.3636 18.6952L11.6364 15.8716L10.9091 18.6952L8 19.4011L10.9091 20.1069L11.6364 22.9305Z"
        fill="#24B2D7"
      />
    </svg>
  );
};

export default SvgInsights1Filled;
