import * as React from "react";

const SvgRouting = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path d="M17 10V6a2 2 0 00-2.109-2A2.118 2.118 0 0013 6.17V18a4.017 4.017 0 01-1.246 2.9A3.968 3.968 0 019 22h-.215A4.09 4.09 0 015 17.83V14a1 1 0 00-1-1H3a1 1 0 010-2h1a3 3 0 013 3v3.83A2.118 2.118 0 008.891 20 2 2 0 0011 18V6.17a4.09 4.09 0 013.787-4.165A4 4 0 0119 6v4a1 1 0 001 1h1a1 1 0 010 2h-1a3 3 0 01-3-3z" />
      <circle cx={4} cy={12} r={2} />
      <circle cx={21} cy={12} r={2} />
    </svg>
  );
};

export default SvgRouting;
