import * as React from "react";

const SvgReservations = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M18 20H6c-.551 0-1-.577-1-1.286V11h14v7.714c0 .709-.449 1.286-1 1.286zm-9.433-5.574a.924.924 0 00-1.285-.03.875.875 0 00-.03 1.257l2.02 2.071a.92.92 0 00.655.276h.004a.917.917 0 00.652-.271l6.161-6.222a.875.875 0 00-.02-1.257.924.924 0 00-1.286.02L9.935 15.83l-1.368-1.403zM6 6h1v1c0 .55.45 1 1 1s1-.45 1-1V6h6v1c0 .55.45 1 1 1s1-.45 1-1V6h1c.551 0 1 .449 1 1v4H5V7c0-.551.449-1 1-1zm12-2h-1V3c0-.55-.45-1-1-1s-1 .45-1 1v1H9V3c0-.55-.45-1-1-1s-1 .45-1 1v1H6C4.346 4 3 5.346 3 7v12c0 1.654 1.346 3 3 3h12c1.654 0 3-1.346 3-3V7c0-1.654-1.346-3-3-3z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgReservations;
