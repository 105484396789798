import * as React from "react";

const SvgAssignCaptain = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path d="M18.4 4.8h-3.762C14.26 3.756 13.27 3 12.1 3c-1.17 0-2.16.756-2.538 1.8H5.8c-.99 0-1.8.81-1.8 1.8v12.6c0 .99.81 1.8 1.8 1.8h12.6c.99 0 1.8-.81 1.8-1.8V6.6c0-.99-.81-1.8-1.8-1.8zm-6.3-.225c.198 0 .369.09.495.225.108.117.18.279.18.45a.68.68 0 01-.675.675.68.68 0 01-.675-.675c0-.171.072-.333.18-.45a.674.674 0 01.495-.225zM18.4 19.2H5.8V6.6h12.6v12.6zM12.1 7.5a2.708 2.708 0 00-2.7 2.7c0 1.485 1.215 2.7 2.7 2.7s2.7-1.215 2.7-2.7-1.215-2.7-2.7-2.7zm0 3.6a.903.903 0 01-.9-.9c0-.495.405-.9.9-.9s.9.405.9.9-.405.9-.9.9zm-5.4 5.823V18.3h10.8v-1.377c0-2.25-3.573-3.222-5.4-3.222-1.827 0-5.4.963-5.4 3.222zm2.079-.423c.621-.504 2.142-1.008 3.321-1.008 1.179 0 2.709.504 3.321 1.008H8.779z" />
    </svg>
  );
};

export default SvgAssignCaptain;
