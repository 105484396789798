import * as React from "react";

const SvgBulkActions = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M2.5 18V6L11.5 12L2.5 18ZM12.5 18V6L21.5 12L12.5 18Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgBulkActions;
