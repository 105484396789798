import * as React from "react";

const SvgFlip = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M14.293 7.207a.999.999 0 010-1.414l3-3a.999.999 0 011.414 0l3 3a.997.997 0 010 1.414.999.999 0 01-1.414 0L19 5.914V17.91c0 1.98-1.583 3.59-3.529 3.59H13a1 1 0 110-2h2.471c.843 0 1.529-.713 1.529-1.59V5.914l-1.293 1.293a.999.999 0 01-1.414 0zM5 18.086V6.09C5 4.11 6.583 2.5 8.529 2.5H11a1 1 0 110 2H8.529C7.687 4.5 7 5.213 7 6.09v11.996l1.293-1.293a1 1 0 011.414 1.414l-3 3a.999.999 0 01-1.414 0l-3-3a.999.999 0 111.414-1.414L5 18.086z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgFlip;
