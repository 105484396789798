import * as React from "react";

const SvgLink = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        d="M7.685 2.6c1.454-1.458 3.802-1.721 5.423-.453a4.042 4.042 0 01.382 6.04l-.949.95a.669.669 0 01-.948 0 .672.672 0 010-.95l.948-.951a2.693 2.693 0 00-.181-3.967c-1.085-.908-2.713-.735-3.712.266l-.849.85a.669.669 0 01-.948 0c-.262-.262-.264-.685.118-1.068l.716-.717zm1.177 3.595L6.195 8.862a.666.666 0 10.943.943l2.667-2.667a.666.666 0 10-.943-.943zm-.675 5.403l-.85.847c-1 .998-2.632 1.17-3.717.265a2.677 2.677 0 01-.182-3.954l.95-.946a.67.67 0 000-.948.675.675 0 00-.95 0l-.85.85c-1.448 1.443-1.702 3.766-.45 5.382a4.04 4.04 0 006.049.398l.95-.947a.67.67 0 000-.947.672.672 0 00-.95 0z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgLink;
