import * as React from "react";

const SvgWarning = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path d="M12 7.5a1 1 0 00-1 1v4a1 1 0 002 0v-4a1 1 0 00-1-1zM12 16.5a1 1 0 100-2 1 1 0 000 2z" />
      <path d="M20.13 9.89l-6-6a3 3 0 00-4.22 0l-6 6a3 3 0 000 4.22l6 6a3 3 0 004.22 0l6-6a3 3 0 000-4.22zm-1.41 2.81l-6 6a1 1 0 01-1.4 0l-6-6a1 1 0 010-1.4l6-6a1 1 0 011.4 0l6 6a1 1 0 010 1.4z" />
    </svg>
  );
};

export default SvgWarning;
