import * as React from "react";

const SvgDistanceFilled = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.3}
        d="M12.3333 34.5167L17.3333 32.5833V13.0833L12.3333 14.7667V34.5167ZM30.6667 34.9167L35.6667 33.2333V13.4833L30.6667 15.4333V34.9167Z"
        fill="#24B2D7"
      />
      <path
        d="M38.1667 9L37.9 9.05L29 12.5L19 9L9.6 12.1667C9.25 12.2833 9 12.5833 9 12.9667V38.1667C9 38.6333 9.36667 39 9.83333 39L10.1 38.95L19 35.5L29 39L38.4 35.8333C38.75 35.7167 39 35.4167 39 35.0333V9.83333C39 9.36667 38.6333 9 38.1667 9ZM17.3333 32.5833L12.3333 34.5167V14.7667L17.3333 13.0833V32.5833ZM27.3333 34.8833L20.6667 32.55V13.1167L27.3333 15.45V34.8833ZM35.6667 33.2333L30.6667 34.9167V15.4333L35.6667 13.5V33.2333Z"
        fill="black"
      />
    </svg>
  );
};

export default SvgDistanceFilled;
