import * as React from "react";

const SvgPeopleCircule = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M12 20c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm0-18C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M13.333 8.667c0-.736-.598-1.334-1.333-1.334s-1.333.598-1.333 1.334c0 .735.598 1.333 1.333 1.333s1.333-.598 1.333-1.333zm1.334 0A2.67 2.67 0 0112 11.333a2.67 2.67 0 01-2.667-2.666A2.67 2.67 0 0112 6a2.67 2.67 0 012.667 2.667zm-7.334 8.666A4.672 4.672 0 0112 12.667a4.672 4.672 0 014.667 4.666.666.666 0 11-1.334 0A3.337 3.337 0 0012 14a3.337 3.337 0 00-3.333 3.333.667.667 0 11-1.334 0z"
        clipRule="evenodd"
      />
      <path d="M12 7.483c.652 0 1.183.531 1.183 1.184h.3c0-.819-.665-1.484-1.483-1.484v.3zm-1.183 1.184c0-.653.53-1.184 1.183-1.184v-.3c-.818 0-1.483.665-1.483 1.484h.3zM12 9.85c-.652 0-1.183-.53-1.183-1.183h-.3c0 .818.665 1.483 1.483 1.483v-.3zm1.183-1.183c0 .652-.53 1.183-1.183 1.183v.3c.818 0 1.483-.665 1.483-1.483h-.3zM12 11.483a2.82 2.82 0 002.817-2.816h-.3A2.52 2.52 0 0112 11.183v.3zM9.183 8.667A2.82 2.82 0 0012 11.483v-.3a2.52 2.52 0 01-2.517-2.516h-.3zM12 5.85a2.82 2.82 0 00-2.817 2.817h.3A2.52 2.52 0 0112 6.15v-.3zm2.817 2.817A2.82 2.82 0 0012 5.85v.3a2.52 2.52 0 012.517 2.517h.3zM12 12.517a4.822 4.822 0 00-4.817 4.816h.3A4.522 4.522 0 0112 12.817v-.3zm4.817 4.816A4.822 4.822 0 0012 12.517v.3a4.522 4.522 0 014.517 4.516h.3zM16 18.15a.816.816 0 00.817-.817h-.3c0 .286-.231.517-.517.517v.3zm-.817-.817c0 .451.365.817.817.817v-.3a.517.517 0 01-.517-.517h-.3zM12 14.15a3.187 3.187 0 013.183 3.183h.3A3.487 3.487 0 0012 13.85v.3zm-3.183 3.183A3.187 3.187 0 0112 14.15v-.3a3.487 3.487 0 00-3.483 3.483h.3zM8 18.15a.816.816 0 00.817-.817h-.3c0 .286-.231.517-.517.517v.3zm-.817-.817c0 .451.365.817.817.817v-.3a.516.516 0 01-.517-.517h-.3z" />
    </svg>
  );
};

export default SvgPeopleCircule;
