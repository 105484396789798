import * as React from "react";
import { SVGProps } from "react";

const SvgArrowCircleRight = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.999 12.005 17 12c0-.033-.016-.06-.019-.092a.937.937 0 0 0-.057-.282c-.027-.068-.073-.122-.114-.182-.031-.044-.048-.095-.086-.135l-2.863-3a1 1 0 1 0-1.447 1.381l1.25 1.31H8a1 1 0 1 0 0 2h5.586l-1.293 1.293a.999.999 0 1 0 1.414 1.414l3-3a.994.994 0 0 0 .292-.702ZM12 20c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8Zm0-18C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2Z"
    />
  </svg>
);

export default SvgArrowCircleRight;
