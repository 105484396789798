import * as React from "react";

const SvgCheckmarkCircle = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M14.705 8.395l-3.784 4.969-1.633-2.087a.999.999 0 10-1.575 1.232l2.43 3.107a1 1 0 00.789.383h.007a.994.994 0 00.788-.394l4.569-6a.999.999 0 10-1.591-1.21zM12 20c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm0-18C6.477 2 2 6.478 2 12s4.477 10 10 10 10-4.478 10-10S17.523 2 12 2z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgCheckmarkCircle;
