import * as React from "react";

const SvgAcuteClock = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <mask
        id="mask0_52_387"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_52_387)">
        <path
          d="M12.5 16.6667C10.6389 16.6667 9.06248 16.0208 7.77081 14.7292C6.47915 13.4375 5.83331 11.8611 5.83331 10C5.83331 8.15278 6.47915 6.57986 7.77081 5.28125C9.06248 3.98264 10.6389 3.33334 12.5 3.33334C14.3472 3.33334 15.9201 3.98264 17.2187 5.28125C18.5173 6.57986 19.1666 8.15278 19.1666 10C19.1666 11.8611 18.5173 13.4375 17.2187 14.7292C15.9201 16.0208 14.3472 16.6667 12.5 16.6667ZM12.5 15C13.8889 15 15.0694 14.5139 16.0416 13.5417C17.0139 12.5694 17.5 11.3889 17.5 10C17.5 8.61111 17.0139 7.43056 16.0416 6.45834C15.0694 5.48611 13.8889 5 12.5 5C11.1111 5 9.93054 5.48611 8.95831 6.45834C7.98609 7.43056 7.49998 8.61111 7.49998 10C7.49998 11.3889 7.98609 12.5694 8.95831 13.5417C9.93054 14.5139 11.1111 15 12.5 15ZM14.3958 13.1042L15.5833 11.9167L13.3333 9.66667V6.66667H11.6666V10.3542L14.3958 13.1042ZM1.66665 7.5V5.83334H4.99998V7.5H1.66665ZM0.833313 10.8333V9.16667H4.99998V10.8333H0.833313ZM1.66665 14.1667V12.5H4.99998V14.1667H1.66665Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
};

export default SvgAcuteClock;
