import theme from "@swvl/theme";
import React, { FC } from "react";
import { MarkersProps } from "./types";
import MarkerWithInfo from "./MarkerWithInfo";

const Markers: FC<MarkersProps> = ({ stations, renderStationLabel, showStationPopover }) => {
  return (
    <>
      {stations.map((station) => {
        const { id, style } = station;
        const icon = {
          path: style?.path || window.google.maps.SymbolPath.CIRCLE,
          scale: style?.scale || 4,
          strokeColor: style?.strokeColor || theme.colors.primary,
          fillColor: style?.fillColor || theme.colors["primary-light-100"],
          fillOpacity: style?.fillOpacity || 1,
          strokeWeight: style?.strokeWeight || 2,
        };
        return (
          <MarkerWithInfo
            key={id}
            icon={icon}
            stations={stations}
            currentStation={station}
            renderStationLabel={renderStationLabel}
            showStationPopover={showStationPopover}
          />
        );
      })}
    </>
  );
};

export default Markers;
