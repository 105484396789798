/**
  Using 11-point scale of 0 to 100 for primary and secondary shades
  0 is "pure" primary
  else it will have suffix of 10, 20, 30, .etc.
*/

export const primary = "#ff002f";

export const primaryLight = {
  "primary-light-10": "#ff1a44",
  "primary-light-20": "#ff3359",
  "primary-light-30": "#ff4d6d",
  "primary-light-40": "#ff6682",
  "primary-light-50": "#ff8097",
  "primary-light-60": "#ff99ac",
  "primary-light-70": "#ffb3c1",
  "primary-light-80": "#ffccd5",
  "primary-light-90": "#fff2f5",
  "primary-light-100": "#ffffff",
};

export const primaryDark = {
  "primary-dark-10": "#e6002a",
  "primary-dark-20": "#cc0026",
  "primary-dark-30": "#b30021",
  "primary-dark-40": "#99001c",
  "primary-dark-50": "#800018",
  "primary-dark-60": "#660013",
  "primary-dark-70": "#4c000e",
  "primary-dark-80": "#330009",
  "primary-dark-90": "#190005",
  "primary-dark-100": "#000000",
};

export const secondary = "#2356f9";

export const secondaryLight = {
  "secondary-light-10": "#3967fa",
  "secondary-light-20": "#4f78fa",
  "secondary-light-30": "#6589fb",
  "secondary-light-40": "#7b9afb",
  "secondary-light-50": "#91abfc",
  "secondary-light-60": "#a7bbfd",
  "secondary-light-70": "#bdccfd",
  "secondary-light-80": "#d3ddfe",
  "secondary-light-90": "#e9eefe",
  "secondary-light-95": "#f4f7ff",
  "secondary-light-100": "#ffffff",
};

export const secondaryDark = {
  "secondary-dark-10": "#204de0",
  "secondary-dark-20": "#1c45c7",
  "secondary-dark-30": "#193cae",
  "secondary-dark-40": "#153495",
  "secondary-dark-50": "#122b7d",
  "secondary-dark-60": "#0e2264",
  "secondary-dark-70": "#0a1a4b",
  "secondary-dark-80": "#071132",
  "secondary-dark-90": "#030919",
  "secondary-dark-100": "#000000",
};

export const tertiary = "#ffd600";
export const gold = "#fdbc2c";

export const info = {
  "info-light": "#ddf1fd",
  "info-dark": "#2ba7f0",
};

export const negative = {
  "negative-ultra-light": "#FEF7F5",
  "negative-light": "#f9e0d7",
  "negative-dark": "#da3b07",
};

export const warning = {
  "warning-light": "#fef5d6",
  "warning-dark": "#fbbf00",
};

export const success = {
  "success-ultra-light": "#F5FAF8",
  "success-light": "#D6EBE4",
  "success-dark": "#008356",
};

export const background = {
  background: "#ffffff",
  "background-primary": "#ffffff",
  "background-secondary": "#f9f9fe",
  "background-tertiary": "#fbfbfe",
  "background-quarternary": "#f1f1f4",
  "background-quinary": "#f0f0f3",
  "background-neutral": "#ececec",
  "bg-alert-ultra-light": "#FEFBFA",
};

export const content = {
  "content-primary": "#181818",
  "content-secondary": "#505050",
  "content-tertiary": "#787878",
  "content-quarternary": "#969696",
  "content-quinary": "#BDBDBD",
};

export const borders = {
  border: "#d9dbe9",
  "border-negative": "#ed9d83",
  "border-warning": "#fddf80",
  "border-success": "#80c1aa",
};

export default {
  primary,
  ...primaryLight,
  ...primaryDark,
  secondary,
  ...secondaryLight,
  ...secondaryDark,
  tertiary,
  gold,
  ...info,
  ...negative,
  ...warning,
  ...success,
  ...background,
  ...content,
  ...borders,
};
