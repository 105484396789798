import * as React from "react";

const SvgQuestionMark = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M12 4c2.757 0 5 2.243 5 5a5.007 5.007 0 01-4 4.898V15a1 1 0 11-2 0v-2a1 1 0 011-1c1.654 0 3-1.346 3-3s-1.346-3-3-3-3 1.346-3 3a1 1 0 11-2 0c0-2.757 2.243-5 5-5zm-1 15a1 1 0 112 0 1 1 0 11-2 0z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgQuestionMark;
