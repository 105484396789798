import { useState, useEffect } from "react";

type Config = {
  leaveDelay?: number;
};

export default function useMountDelay(renderFlag: boolean, { leaveDelay = 700 }: Config = {}) {
  const [shouldRender, setShouldRendered] = useState(renderFlag);
  const [isEntering, setEntering] = useState(false);

  function enter() {
    setEntering(true);
  }

  function leave() {
    setEntering(false);
  }

  useEffect(() => {
    if (renderFlag) {
      setShouldRendered(true);
    } else {
      leave();
      setTimeout(() => setShouldRendered(false), leaveDelay);
    }
  }, [renderFlag]);

  useEffect(() => {
    if (shouldRender) {
      setTimeout(enter, 10);
    }
  }, [shouldRender]);

  return [shouldRender, isEntering];
}
