/** @jsx jsx */
import { jsx } from "theme-ui";
import { FC, Fragment, memo, useCallback, useEffect, useRef, useMemo, useState } from "react";
import { GoogleMap, OverlayView, Marker } from "@react-google-maps/api";
import { Global, css } from "@emotion/core";
import io from "socket.io-client";
import theme from "@swvl/theme";

import ZoomControls from "./ZoomControls";

import useRenderBus from "./useRenderBus";
import useRideSubscribe from "./useRideSubscribe";
import usePath from "./usePath";
import Markers from "./Markers";
import { BUS } from "./defaults";
import mapTheme from "./mapTheme";
import { BusIconOptions, MapWrapperProps, Station } from "./types";

const MapWrapper: FC<MapWrapperProps> = (props) => {
  const {
    mapContainerStyle,
    routes,
    selectedRouteId = "",
    dimmedColorForRoutes = theme.colors["background-quarternary"],
    socketConnectionOptions,
    trackedRideIds,
    children,
    onMapInit,
    zoom = 12,
    hideZoomControls = false,
    disableFitBounds = false,
    showStationPopover = false,
    renderStationLabel = () => null,
    getBusIconOptions,
    busAnimationOptions,
    renderBusPopup,
    options,
    ...rest
  } = props;

  // https://github.com/JustFly1984/react-google-maps-api/issues/887#issuecomment-633197239
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const socketRef = useRef<SocketIOClient.Socket | null>(null);
  const [isSocketConnected, setSocketConnected] = useState<boolean>(false);

  const onLoad = useCallback(function onLoad(map) {
    setMap(map);
    onMapInit && onMapInit(map);
  }, []);

  usePath({ routes, selectedRouteId, dimmedColorForRoutes, map, disableFitBounds });

  // Effect for handling socket connection and listeners
  useEffect(() => {
    const { url, query } = socketConnectionOptions ?? {};
    if (url && trackedRideIds && !isSocketConnected) {
      socketRef.current = io(url, {
        query,
        transports: ["websocket"],
      });

      /** Listen to sockets connect */
      socketRef.current.on("connect", () => {
        setSocketConnected(true);
      });

      /** Listen to sockets connection error */
      socketRef.current.on("connect_error", (err) => {
        socketConnectionOptions?.onConnectionError?.({ error: err });
      });

      socketRef.current.on("disconnect", () => {
        setSocketConnected(false);
      });
    }

    /** On trackedRideIds undefined or empty, clean up any old sockets connections and listeners */
    if (
      socketRef.current &&
      isSocketConnected &&
      (!trackedRideIds || trackedRideIds?.length === 0)
    ) {
      socketRef.current.disconnect();
      socketRef.current = null;
      setSocketConnected(false);
    }
  }, [trackedRideIds]);

  /** On unmount, clean up any old sockets connections and listeners */
  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
        setSocketConnected(false);
      }
    };
  }, []);

  const stations = [] as Station[];

  if (routes) {
    for (const [, route] of Object.entries(routes)) {
      const { stations: routeStations } = route;
      if (routeStations && routeStations.length > 0) {
        routeStations.forEach((station) => stations.push(station));
      }
    }
  }

  return (
    <GoogleMap
      onLoad={onLoad}
      zoom={zoom}
      mapContainerStyle={mapContainerStyle}
      options={{
        ...options,
        styles: mapTheme,
        disableDefaultUI: true,
      }}
      {...rest}
    >
      {children}
      {trackedRideIds?.map((rideId) => (
        <RideTracking
          id={rideId}
          map={map}
          key={rideId}
          socketRef={socketRef}
          isSocketConnected={isSocketConnected}
          socketConnectionOptions={socketConnectionOptions}
          getBusIconOptions={getBusIconOptions}
          busAnimationOptions={busAnimationOptions}
          renderBusPopup={renderBusPopup}
        />
      ))}
      {stations?.length > 0 ? (
        <Markers
          stations={stations}
          renderStationLabel={renderStationLabel}
          showStationPopover={showStationPopover}
        />
      ) : null}
      {map && !hideZoomControls && <ZoomControls map={map} />}
    </GoogleMap>
  );
};

const RideTracking: FC<
  Partial<MapWrapperProps> & {
    id: string;
    socketRef: React.MutableRefObject<SocketIOClient.Socket | null>;
    isSocketConnected: boolean;
    map: google.maps.Map | null;
  }
> = ({
  id,
  socketRef,
  isSocketConnected,
  socketConnectionOptions,
  getBusIconOptions,
  busAnimationOptions,
  renderBusPopup,
  map,
}) => {
  const busIconOptions: BusIconOptions = useMemo(
    () => (getBusIconOptions ? getBusIconOptions(id) : {}),
    [getBusIconOptions]
  );
  const {
    width = BUS.width,
    height = BUS.height,
    scale = BUS.scale,
    onClick: onBusClick,
    onMouseOver: onBusMouseOver,
    onMouseOut: onBusMouseOut,
  } = busIconOptions;

  // Subscribe/Listen to current ride location
  const currentLocation = useRideSubscribe({
    rideId: id,
    socketRef,
    isSocketConnected,
    socketConnectionOptions,
  });

  // Render tracked bus
  useRenderBus({
    rideId: id,
    currentBusLocation: currentLocation,
    busIconOptions,
    busAnimationOptions,
    map,
  });

  const getBusPopupOffset = (width: number, height: number) => {
    const offsetWidth = 45;
    const offsetHeight = -35;
    return {
      x: -(width / 2) + offsetWidth,
      y: -(height / 2) + offsetHeight,
    };
  };

  return (
    <Fragment>
      <Global
        styles={css`
          .gm-style .gm-style-iw-t::after {
            background: none;
            box-shadow: none;
          }
        `}
      />
      {currentLocation && (
        <Marker
          key={"Marker" + id}
          position={{
            lat: currentLocation.lat,
            lng: currentLocation.lng,
          }}
          cursor="pointer"
          icon={{
            fillOpacity: 0,
            strokeWeight: 0,
            anchor: new window.google.maps.Point(width / 2, height / 2),
            scale: scale,
            path: `M 0 0 h ${width} v ${height} h -${width} Z`,
            rotation: currentLocation.bearing,
          }}
          onClick={() => {
            onBusClick && onBusClick(id);
          }}
          onMouseOver={() => {
            onBusMouseOver && onBusMouseOver(id);
          }}
          onMouseOut={() => {
            onBusMouseOut && onBusMouseOut(id);
          }}
        />
      )}
      {currentLocation && renderBusPopup ? (
        <OverlayView
          position={{
            lat: currentLocation.lat,
            lng: currentLocation.lng,
          }}
          mapPaneName="floatPane"
          getPixelPositionOffset={getBusPopupOffset}
          css={{
            backgroundColor: "red",
          }}
        >
          {renderBusPopup({
            rideId: id,
            location: currentLocation,
          })}
        </OverlayView>
      ) : null}
    </Fragment>
  );
};

export default memo(MapWrapper);
