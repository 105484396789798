/** @jsx jsx */
import { jsx } from "theme-ui";
import { isValidElement } from "react";
import {
  createContext,
  useContext,
  useState,
  Fragment,
  Children,
  FC,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import { ChevronDownIcon } from "@swvl/icons";
import Tooltip from "@swvl/tooltip";

const MapInfoContext = createContext<{
  isListOpen: boolean;
  setListOpen: Dispatch<SetStateAction<boolean>>;
}>({
  isListOpen: false,
  setListOpen: () => false,
});

const useMapInfoContext = () => {
  const context = useContext(MapInfoContext);

  if (context === undefined) {
    console.error("MapInfo compound components should be use inside the the <MapInfo /> component");
  }
  return context;
};

const Header: FC = ({ children }) => {
  const { isListOpen, setListOpen } = useMapInfoContext();

  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        py: 12,
        px: "spacing-xs",
        cursor: "pointer",
        borderRadius: 12,
        "&:hover": {
          bg: "secondary-light-90",
        },
        "&:active": {
          bg: "secondary-light-80",
        },
      }}
      onClick={() => setListOpen(!isListOpen)}
    >
      <p
        data-for="headerTooltip"
        data-tip={children}
        data-delay-show={300}
        sx={{
          variant: "text.p-large-bold",
          m: 0,
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordBreak: "break-word",
          display: "-webkit-box",
          WebkitLineClamp: "2",
          "-webkit-box-orient": "vertical",
          paddingInlineEnd: "spacing-xs",
        }}
      >
        {children}
      </p>
      <Tooltip id="headerTooltip" />
      <ChevronDownIcon
        sx={{
          alignSelf: "baseline",
          flexShrink: 0,
          transform: isListOpen ? "rotate(-180deg)" : null,
          transition: "transform 0.2s",
        }}
      />
    </div>
  );
};

const SubHeader: FC = ({ children }) => {
  return (
    <p sx={{ variant: "text.p-large", mt: 12, mb: "spacing-xs", px: "spacing-xs" }}>{children}</p>
  );
};

const Body: FC = (props) => <Fragment {...props} />;

export const MapInfo = ({ children }: { children: ReactNode }) => {
  const [isListOpen, setListOpen] = useState(false);
  const iterableChildren = Children.toArray(children);
  const headers = iterableChildren.filter((child) => {
    if (isValidElement(child)) {
      if (typeof child.type === "string") return false;

      return child.type === Header || child.type === SubHeader;
    }
    return false;
  });
  const body = iterableChildren.filter((child) => {
    if (isValidElement(child)) {
      return typeof child.type !== "string" && child.type === Body;
    }

    return false;
  });

  return (
    <MapInfoContext.Provider value={{ isListOpen, setListOpen }}>
      <div
        sx={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          top: "spacing-l",
          left: "spacing-l",
          width: 293,
          minHeight: 116,
          maxHeight: "calc(100% - 110px)",
          p: "spacing-xs",
          borderRadius: 12,
          backgroundColor: "primary-light-100",
          boxShadow: "menu",
          overflow: "hidden",
        }}
      >
        {headers}

        <div sx={{ overflow: "auto" }}>
          <div
            sx={{
              overflow: "hidden",
              height: isListOpen ? null : 0,
            }}
          >
            {body}
          </div>
        </div>
      </div>
    </MapInfoContext.Provider>
  );
};

MapInfo.Header = Header;
MapInfo.SubHeader = SubHeader;
MapInfo.Body = Body;
