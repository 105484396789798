import * as React from "react";

const SvgMoreVertical = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M12 7a2 2 0 10-.001-4.001A2 2 0 0012 7zm0 3a2 2 0 10.001 4.001A2 2 0 0012 10zm-2 9a2 2 0 114.001.001A2 2 0 0110 19z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgMoreVertical;
