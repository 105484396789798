import * as React from "react";

const SvgPercent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M19.74 4.26a.887.887 0 00-1.257 0L4.26 18.484a.886.886 0 000 1.257.889.889 0 001.257 0L19.74 5.518a.889.889 0 000-1.257zM16 19c-.827 0-1.5-.673-1.5-1.5S15.173 16 16 16s1.5.673 1.5 1.5S16.827 19 16 19zm0-5c-1.93 0-3.5 1.57-3.5 3.5S14.07 21 16 21s3.5-1.57 3.5-3.5S17.93 14 16 14zM8 6c.827 0 1.5.673 1.5 1.5S8.827 9 8 9s-1.5-.673-1.5-1.5S7.173 6 8 6zm0 5c1.93 0 3.5-1.57 3.5-3.5S9.93 4 8 4 4.5 5.57 4.5 7.5 6.07 11 8 11z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgPercent;
