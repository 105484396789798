import * as React from "react";

const SvgCheckmark = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M9.863 18a1 1 0 01-.729-.315l-4.863-5.179a1 1 0 111.457-1.369l4.125 4.391 8.408-9.202a1 1 0 011.477 1.348l-9.137 10a.998.998 0 01-.73.326h-.008z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgCheckmark;
