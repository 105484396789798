import * as React from "react";

const SvgCaptainDelete = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M5.009 8c.001-.208.018-.415.05-.62-1.264-.46-2.056-1.13-2.066-1.88 0-1.38 2.698-2.5 6.018-2.5s6.018 1.12 6.018 2.5c0 .75-.792 1.42-2.056 1.88a4.2 4.2 0 01.06.62c0 1.06-.423 2.078-1.175 2.828a4.018 4.018 0 01-5.674 0A3.994 3.994 0 015.01 8zm2.644 1.414c.376.375.886.586 1.418.586h-.05a2.01 2.01 0 001.899-1.223c.104-.246.157-.51.157-.777a.607.607 0 000-.14c-.664.095-1.335.141-2.006.14a13.994 13.994 0 01-2.006-.14.608.608 0 000 .14c0 .53.212 1.04.588 1.414zM9.02 5a9.948 9.948 0 00-3.07.43l-.09.11a9.857 9.857 0 003.16.46 9.806 9.806 0 003.16-.46l-.08-.11A10.29 10.29 0 009.02 5zM8.18 19h1.675a1.005 1.005 0 011.003 1 .998.998 0 01-1.003 1H8.179a1.005 1.005 0 01-1.003-1 .998.998 0 011.003-1zm13.53-8.41a1.002 1.002 0 00-.712-1.714c-.267 0-.523.106-.712.294l-1.414 1.42-1.415-1.42a1.009 1.009 0 00-1.719.71c0 .266.106.522.295.71L17.456 12l-1.424 1.41a1.002 1.002 0 00.712 1.714c.267 0 .523-.106.712-.294l1.415-1.42 1.414 1.42a1.008 1.008 0 001.72-.71c0-.266-.107-.522-.296-.71L20.285 12l1.424-1.41zM4.056 16.05a7.032 7.032 0 0111.452 2.271c.353.85.534 1.76.534 2.679h-2.006a4.992 4.992 0 00-1.469-3.535 5.023 5.023 0 00-7.092 0A4.992 4.992 0 004.006 21H2a6.99 6.99 0 012.056-4.95z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgCaptainDelete;
