import * as React from "react";

const SvgCaptain = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M4.76 8.24a2.32 2.32 0 010-.53C3.68 7.36 3 6.78 3 6.14c0-1.18 2.3-2.13 5.14-2.13s5.15.96 5.15 2.14a1 1 0 01-.19.56 5.55 5.55 0 013.61-1.14C19.08 5.57 21 6.65 21 8a2.2 2.2 0 01-1.41 1.77v.33a2.86 2.86 0 11-5.71 0v-.33a2.19 2.19 0 01-1.45-1.81c.008-.33.12-.648.32-.91a4.16 4.16 0 01-1.23.66c.054.172.087.35.1.53a3.43 3.43 0 01-6.86 0zm11.488 2.543a.84.84 0 00.512.177l-.05.04a.84.84 0 00.87-.67 5.552 5.552 0 01-.82.05 5.339 5.339 0 01-.81-.05.84.84 0 00.298.453zM18.5 7.83a4.7 4.7 0 00-1.74-.3l-.05.04a4.64 4.64 0 00-1.71.3c-.056.04-.11.083-.16.13a4.46 4.46 0 001.92.38A4.48 4.48 0 0018.69 8a1.54 1.54 0 00-.19-.17zM7.132 9.288c.267.268.63.42 1.008.422a1.43 1.43 0 001.41-1.43.28.28 0 000-.09c-.47.061-.945.091-1.42.09-.475.001-.95-.029-1.42-.09a.28.28 0 000 .09c.003.378.154.74.422 1.008zM9.82 6.14A9 9 0 008.14 6a9 9 0 00-1.68.14 10 10 0 001.68.14 10 10 0 001.68-.14zm-6.019 8.941A6.15 6.15 0 018.14 13.28a6.16 6.16 0 016.15 6.14h-2a4.15 4.15 0 00-8.29 0H2a6.15 6.15 0 011.801-4.339zM9 17.57H7.31a1.1 1.1 0 00-1.17 1 1.1 1.1 0 001.17 1H9a1 1 0 000-2zm5.07-2.72a5.23 5.23 0 012.64-.71A5.29 5.29 0 0122 19.42h-2a3.29 3.29 0 00-4.93-2.84l-1-1.73z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgCaptain;
