import * as React from "react";

const SvgDiagonalarrowleftdown = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M17.707 6.293a.999.999 0 00-1.414 0L8 14.586V9a1 1 0 10-2 0v8a1 1 0 001 1h8a1 1 0 100-2H9.414l8.293-8.293a.999.999 0 000-1.414z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgDiagonalarrowleftdown;
