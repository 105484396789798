import * as React from "react";

const SvgDragIndicator = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path d="M4.166 12c0 .917-.75 1.667-1.666 1.667-.917 0-1.667-.75-1.667-1.667s.75-1.667 1.667-1.667c.916 0 1.666.75 1.666 1.667zM2.5 5.333C1.583 5.333.833 6.083.833 7s.75 1.667 1.667 1.667c.916 0 1.666-.75 1.666-1.667S3.416 5.333 2.5 5.333zm0-5C1.583.333.833 1.083.833 2s.75 1.667 1.667 1.667c.916 0 1.666-.75 1.666-1.667S3.416.333 2.5.333zm5 3.334c.916 0 1.666-.75 1.666-1.667S8.416.333 7.5.333c-.917 0-1.667.75-1.667 1.667s.75 1.667 1.667 1.667zm0 1.666c-.917 0-1.667.75-1.667 1.667s.75 1.667 1.667 1.667c.916 0 1.666-.75 1.666-1.667S8.416 5.333 7.5 5.333zm0 5c-.917 0-1.667.75-1.667 1.667s.75 1.667 1.667 1.667c.916 0 1.666-.75 1.666-1.667s-.75-1.667-1.666-1.667z" />
    </svg>
  );
};

export default SvgDragIndicator;
