import * as React from "react";

const SvgDispatchRides = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M19 12.053V7c0-2.21-1.79-4-4-4s-4 1.79-4 4v10c0 1.1-.9 2-2 2s-2-.9-2-2V8.82C8.16 8.4 9 7.3 9 6c0-1.66-1.34-3-3-3S3 4.34 3 6c0 1.3.84 2.4 2 2.82V17c0 2.21 1.79 4 4 4s4-1.79 4-4V7c0-1.1.9-2 2-2s2 .9 2 2v5.15a5.265 5.265 0 012-.097zM5 6c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M15.41 16.873a.848.848 0 00-1.157-.026.75.75 0 00-.027 1.1l1.82 1.812a.84.84 0 00.588.241h.003a.837.837 0 00.588-.237l5.545-5.444a.75.75 0 00-.02-1.1.849.849 0 00-1.156.018L16.642 18.1l-1.232-1.227z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgDispatchRides;
