import * as React from "react";

const SvgEyeOff = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M12 13.5c-.827 0-1.5-.673-1.5-1.5 0-.013.003-.026.006-.039.003-.011.006-.023.006-.035l1.562 1.562a.184.184 0 00-.035.006c-.013.003-.026.006-.039.006zM4.707 3.293a.999.999 0 10-1.414 1.414l5.63 5.63A3.471 3.471 0 008.5 12c0 1.93 1.57 3.5 3.5 3.5.589 0 1.154-.147 1.663-.423l5.63 5.63a.997.997 0 001.414 0 .999.999 0 000-1.414l-16-16zm7.513 13.705c-4.305.1-7.115-3.583-8.047-5.002a13.432 13.432 0 012.283-2.711L5.045 7.873a15.605 15.605 0 00-2.912 3.63.999.999 0 000 .995C2.762 13.595 6.162 19 12.025 19l.246-.003a9.35 9.35 0 003.227-.67l-1.58-1.58a7.29 7.29 0 01-1.698.25zm-.49-11.995c5.975-.187 9.5 5.387 10.138 6.5.176.307.176.686 0 .994a15.646 15.646 0 01-2.913 3.63l-1.411-1.412a13.395 13.395 0 002.283-2.71c-.932-1.42-3.755-5.11-8.046-5.003-.6.015-1.163.11-1.7.251l-1.58-1.58a9.357 9.357 0 013.229-.67z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgEyeOff;
