import { Theme, useThemeUI } from "theme-ui";
import { ExactContextValue } from "./types";
import colors from "./colors";
import { zIndices } from "./z-indices";
import typography from "./typography";
import useCSSVariables, { styleTagId } from "./useCSSVariables";
import typographyAr from "./typography-ar";

const makeTheme = <T extends Theme>(t: T) => t;
const theme = makeTheme({
  colors,
  zIndices,
  ...typography,
  space: {
    "spacing-xxs": "4px",
    "spacing-xs": "8px",
    "spacing-s": "16px",
    "spacing-m": "24px",
    "spacing-m-l": "32px",
    "spacing-l": "48px",
    "spacing-xl": "64px",
    "spacing-xxl": "80px",
    "spacing-xxxl": "96px",
  },
  breakpoints: [
    "@media (min-width: 576px)",
    "@media (min-width: 768px)",
    "@media (min-width: 992px)",
    "@media (min-width: 1200px)",
  ],
  transitions: {
    timings: ["70ms", "250ms"],
    easing: {
      smooth: "cubic-bezier(0.2, 0, 1, 0.9)",
    },
  },
  buttons: {
    small: {
      px: "spacing-s",
      height: 32,
    },
    medium: {
      px: "spacing-m",
      height: 40,
    },
    large: {
      px: "spacing-m",
      height: 48,
    },
  },
  shadows: {
    menu: "0px 0px 1px #C7C7C7, 0px 3px 16px #DADADA",
    borderTop: "inset 0px 1px 0px #d9dbe9",
    borderBottom: "inset 0px -1px 0px #d9dbe9",
    borderY: "inset 0px -1px 0px #d9dbe9, inset 0px 1px 0px #d9dbe9",
  },
  avatar: {
    small: {
      width: 32,
      height: 32,
    },
    medium: {
      width: 72,
      height: 72,
    },
    large: {
      width: 96,
      height: 96,
    },
  },
  alerts: {
    light: {
      info: {
        color: "content-primary",
        bg: "info-light",
      },
      success: {
        color: "content-primary",
        bg: "success-light",
      },
      warning: {
        color: "content-primary",
        bg: "warning-light",
      },
      error: {
        color: "content-primary",
        bg: "negative-light",
      },
    },
    dark: {
      info: {
        color: "content-primary",
        bg: "info-dark",
      },
      success: {
        color: "background-primary",
        bg: "success-dark",
      },
      warning: {
        color: "content-primary",
        bg: "warning-dark",
      },
      error: {
        color: "background-primary",
        bg: "negative-dark",
      },
    },
  },
  styles: {
    spinner: {
      color: colors.primary,
    },
  },
});

/** Arabic mode styles */
export const arabicTheme = {
  ...theme,
  ...typographyAr,
};

export const useTheme = (useThemeUI as unknown) as () => ExactContextValue;
export { ThemeProvider, jsx } from "theme-ui";

export { useCSSVariables, styleTagId };
export * from "./types";

export default theme;
