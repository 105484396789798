import { useEffect, useState } from "react";
import { Location, RideUpdate, SocketConnectionOptions } from "./types";

const useRideSubscribe = ({
  rideId,
  socketRef,
  isSocketConnected,
  socketConnectionOptions,
}: {
  rideId: string;
  socketRef: React.MutableRefObject<SocketIOClient.Socket | null>;
  isSocketConnected: boolean;
  socketConnectionOptions?: SocketConnectionOptions;
}) => {
  const [location, setLocation] = useState<Location | null>(null);

  useEffect(() => {
    if (socketRef.current) {
      socketRef.current.on(
        "ride:location:update",
        ({ location, rideId: currId }: { location: Location; rideId: string }) => {
          if (rideId === currId) {
            setLocation(location);
            socketConnectionOptions?.onLocationUpdate?.({ location, rideId });
          }
        }
      );
      socketRef.current.on("ride:update", (rideUpdate: RideUpdate) => {
        if (rideId === rideUpdate._id) {
          socketConnectionOptions?.onRideUpdate?.(rideUpdate);
        }
      });
    }
  }, [socketRef.current]);

  /** Effect for handling subscribing and re-subscribing to rides */
  useEffect(() => {
    if (socketRef.current && isSocketConnected) {
      /** Emit sockets subscribe to ride */
      socketRef.current.emit("ride:subscribe", { rideId });
      socketConnectionOptions?.onSubscribe?.({ rideId });
    }
  }, [isSocketConnected]);

  /** Clean up any old ride subscription */
  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.emit("ride:unsubscribe", { rideId });
        // setLocation(null);
        socketConnectionOptions?.onUnsubscribe?.({ rideId });
      }
    };
  }, []);

  return location;
};

export default useRideSubscribe;
