/** @jsx jsx */
import { jsx } from "theme-ui";
import { FC, useState, useEffect, useCallback, useRef } from "react";
import Input from "@swvl/input";
import { Time, AM, PM, AMPMWrapper, Content } from "./styled";
import { PopoverTimePickerProps } from "./types";
import { formatToTwoDigits } from "./utils";

export const PopoverTimePicker: FC<PopoverTimePickerProps> = ({
  onChange,
  initialHours = "00",
  initialMinutes = "00",
  initialAmPm = "am",
  isOpen,
  children,
  ...rest
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isVisible, setVisibility] = useState<boolean>(false);

  const [hours, setHours] = useState(initialHours);
  const [mins, setMins] = useState(initialMinutes);
  const [AMOrPMOption, setAMOrPMOption] = useState(initialAmPm);

  const closeOnEsc = useCallback((e) => {
    if (e.keyCode === 27) {
      setVisibility(false);
    }
  }, []);

  const closeOnClickOutside = useCallback((e) => {
    if (e.target === inputRef.current) {
      return;
    }
    const node = wrapperRef.current;
    if (!node?.contains(e.target as Node)) {
      setVisibility(false);
    }
  }, []);

  const isControlled = isOpen !== undefined;

  useEffect(() => {
    if (!isControlled) {
      if (isVisible) {
        document.addEventListener("keydown", closeOnEsc);
        document.addEventListener("click", closeOnClickOutside, false);
      } else {
        document.removeEventListener("keydown", closeOnEsc);
        document.removeEventListener("click", closeOnClickOutside, false);
      }
    }

    return () => {
      document.removeEventListener("keydown", closeOnEsc);
      document.removeEventListener("click", closeOnClickOutside, false);
    };
  }, [isVisible]);

  useEffect(() => {
    if (isControlled) setVisibility(isOpen as boolean);
  }, [isControlled, isOpen]);

  const onMinsChange = useCallback(
    (e) => {
      let value;
      const oldMins = mins;

      const target = e.target as HTMLInputElement;
      const newMins = +target.value;
      if (newMins >= 0 && newMins < 60) {
        setMins(formatToTwoDigits(newMins));
        value = +hours || +newMins ? `${hours}:${newMins} ${AMOrPMOption}` : undefined;
      } else {
        setMins(formatToTwoDigits(oldMins));
        value = +hours || +oldMins ? `${hours}:${oldMins} ${AMOrPMOption}` : undefined;
      }

      onChange(value);
    },
    [hours, mins, AMOrPMOption, onChange]
  );

  const onHoursChange = useCallback(
    (e) => {
      let value;
      const oldHours = hours;

      const target = e.target as HTMLInputElement;
      const newHours = +target.value;
      if (newHours >= 0 && newHours <= 12) {
        setHours(formatToTwoDigits(newHours));
        value = +newHours || +mins ? `${newHours}:${mins} ${AMOrPMOption}` : undefined;
      } else {
        setHours(formatToTwoDigits(oldHours));
        value = +oldHours || +mins ? `${oldHours}:${mins} ${AMOrPMOption}` : undefined;
      }

      onChange(value);
    },
    [hours, mins, AMOrPMOption, onChange]
  );

  const onAmPmChange = useCallback(
    (e) => {
      setAMOrPMOption(e.target.value);

      const value = +hours || +mins ? `${hours}:${mins} ${e.target.value}` : undefined;

      onChange(value);
    },
    [hours, mins, onChange]
  );

  return (
    <div sx={{ position: "relative" }} ref={wrapperRef}>
      <Input
        data-test-id="popoover-time-picker"
        readOnly
        ref={inputRef}
        placeholder="Add Time"
        value={+hours || +mins ? `${hours}:${mins} ${AMOrPMOption.toUpperCase()}` : undefined}
        onClick={() => {
          if (!isControlled) setVisibility(true);
        }}
        {...rest}
      />
      {isVisible ? (
        <Content>
          <Time
            data-test-id="hours"
            type="number"
            min="1"
            max="12"
            onChange={onHoursChange}
            value={hours ? `${hours}` : undefined}
          />

          <Time
            data-test-id="minutes"
            type="number"
            min="1"
            max="60"
            onChange={onMinsChange}
            value={mins ? `${mins}` : undefined}
          />
          <AMPMWrapper>
            <AM onChange={onAmPmChange} data-test-id="am" checked={AMOrPMOption === "am"} />
            <PM onChange={onAmPmChange} data-test-id="pm" checked={AMOrPMOption === "pm"} />
          </AMPMWrapper>
          {children}
        </Content>
      ) : null}
    </div>
  );
};
