import * as React from "react";

const SvgBriefcase = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M19.999 18c0 .551-.449 1-1 1h-2V9h2c.551 0 1 .449 1 1v8zm-16 0v-8c0-.551.449-1 1-1h2v10h-2c-.551 0-1-.449-1-1zm6-12.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5V7h-4V5.5zm-1 13.5h6V9h-6v10zm10-12h-3V5.5c0-1.378-1.122-2.5-2.5-2.5h-3a2.503 2.503 0 00-2.5 2.5V7h-3c-1.654 0-3 1.346-3 3v8c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3v-8c0-1.654-1.346-3-3-3z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgBriefcase;
