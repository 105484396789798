// Created on https://snazzymaps.com/editor
import { GoogleMapProps } from "@react-google-maps/api";

type GoogleMapOptionsProps = Required<Pick<GoogleMapProps, "options">>;

const googleMapOptions: GoogleMapOptionsProps = {
  options: {
    styles: [
      {
        featureType: "administrative",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#ebeeeb",
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "labels.icon",
        stylers: [
          {
            color: "#c7c5c5",
          },
        ],
      },
      {
        featureType: "landscape",
        elementType: "labels.icon",
        stylers: [
          {
            color: "#c7c5c5",
          },
        ],
      },
      {
        featureType: "landscape.man_made",
        elementType: "geometry.stroke",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "landscape.natural",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#ebeeeb",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#ebeeeb",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#c7c5c5",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.icon",
        stylers: [
          {
            color: "#c7c5c5",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [
          {
            saturation: -100,
          },
          {
            lightness: 0,
          },
          {
            gamma: 1,
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#c7c5c5",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.icon",
        stylers: [
          {
            color: "#c7c5c5",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#fefefe",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#c7c5c5",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [
          {
            color: "#c7c5c5",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [
          {
            saturation: 0,
          },
          {
            lightness: 0,
          },
          {
            color: "#f5fcfb",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry.stroke",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.icon",
        stylers: [
          {
            color: "#c7c5c5",
          },
        ],
      },
    ],
  },
};

export default googleMapOptions.options.styles;
