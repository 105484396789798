import * as React from "react";

const SvgUnderlineEdit = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9831 7.32291L6.36614 12.9399L6.10214 15.8959L9.07914 15.6249L14.6791 10.0189L11.9831 7.32291ZM17.9661 6.72891L15.2711 4.03491L13.3231 5.98291L16.0181 8.67891L17.9661 6.72891ZM5.09114 17.9959C5.06014 17.9989 5.03014 17.9999 5.00014 17.9999C4.73614 17.9999 4.48114 17.8959 4.29314 17.7069C4.08314 17.4969 3.97714 17.2049 4.00414 16.9099L4.38314 12.7389C4.42514 12.2819 4.62714 11.8509 4.95214 11.5259L13.9481 2.52891C14.6501 1.82491 15.9241 1.85991 16.6641 2.59891L19.4021 5.33691H19.4031C20.1691 6.10391 20.1991 7.32091 19.4711 8.05091L10.4741 17.0479C10.1491 17.3739 9.71914 17.5749 9.26114 17.6169L5.09114 17.9959ZM5 20H19C19.55 20 20 20.45 20 21C20 21.55 19.55 22 19 22H5C4.45 22 4 21.55 4 21C4 20.45 4.45 20 5 20Z"
      />
    </svg>
  );
};

export default SvgUnderlineEdit;
