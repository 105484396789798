import * as React from "react";

const SvgHelpCenter = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.55555 6.84118L3.60738 4.8929L2.96098 4.24649L2.31539 3.60083C0.816602 5.39435 0 7.63435 0 10C0 12.3657 0.816562 14.6056 2.3152 16.399L2.96078 15.7534L3.60719 15.107L5.55555 13.1588C4.9198 12.2668 4.54543 11.1764 4.54543 10C4.54543 8.82368 4.9198 7.73313 5.55555 6.84118Z"
        fill="#D1E0FF"
      />
      <path
        d="M17.6847 3.60083L17.0391 4.24646L16.3927 4.89286L14.4456 6.84001C14.4549 6.8531 14.4647 6.86575 14.474 6.87899C15.1155 7.79716 15.4546 8.87638 15.4547 9.99997C15.4547 11.1763 15.0803 12.2669 14.4446 13.1589L16.3927 15.1071L17.0391 15.7535L17.6848 16.3991C19.1835 14.6056 20 12.3657 20 9.99997C19.9999 7.63423 19.1834 5.39431 17.6847 3.60083Z"
        fill="#D1E0FF"
      />
      <path
        d="M9.99997 0C7.63435 0 5.39455 0.816405 3.60107 2.31515L4.24666 2.96078L4.89307 3.60718L6.84123 5.55542C7.73302 4.9198 8.82345 4.54554 9.99958 4.54554C10.6764 4.54554 11.3363 4.66792 11.962 4.90925C12.3903 5.07445 12.7919 5.29148 13.1606 5.55367L15.1069 3.60726L15.7533 2.96078L16.3989 2.31515C14.6055 0.816484 12.3656 0 9.99997 0Z"
        fill="#155EEF"
      />
      <path
        d="M15.7535 17.0391L15.1071 16.3927L13.1589 14.4444C12.2669 15.0802 11.1765 15.4545 10.0002 15.4545H9.9999C8.82353 15.4545 7.73306 15.0801 6.84107 14.4443L4.89282 16.3926L4.24642 17.039L3.60083 17.6846C5.39423 19.1834 7.63415 19.9999 9.9999 19.9999C12.3656 19.9999 14.6056 19.1833 16.3991 17.6846L15.7535 17.0391Z"
        fill="#155EEF"
      />
    </svg>
  );
};

export default SvgHelpCenter;
