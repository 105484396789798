import { useEffect } from "react";
export const styleTagId = "theme-variables";

type Colors = { [key: string]: string | Colors };

export default function useCSSVariables(theme) {
  const isObject = (prop) => {
    return typeof prop === "object" && prop !== null;
  };
  const constructCSS = (colors: Colors) => {
    let css = "";
    Object.entries(colors).forEach(([color, colorValue]: [string, unknown]) => {
      if (isObject(colorValue)) {
        Object.entries(colorValue as { [key: string]: string }).forEach(([weight, weightValue]) => {
          css += `--swvl-color-${color}-${weight}: ${weightValue};`;
        });
      } else {
        css += `--swvl-color-${color}: ${colorValue};`;
      }
    });

    return css;
  };

  const updateCSSVariables = (colors: Colors) => {
    const css = constructCSS(colors);
    const newStyle = document.createElement("style");
    newStyle.id = styleTagId;
    newStyle.type = "text/css";
    // === This is required for IE8 and below it has a type error as the prop is deprecated === /
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (newStyle.styleSheet) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newStyle.styleSheet.cssText = `:root {${css}}`;
    } else {
      newStyle.appendChild(document.createTextNode(`:root {${css}}`));
    }

    const head = document.head || document.getElementsByTagName("head")[0];
    const variablesStyleTag = document.querySelector(`#${styleTagId}`);
    if (variablesStyleTag) {
      head.replaceChild(newStyle, variablesStyleTag);
    } else {
      head.appendChild(newStyle);
    }
  };

  useEffect(() => {
    const { colors } = theme;
    if (colors) {
      updateCSSVariables(colors);
    }
  }, [theme]);
}
