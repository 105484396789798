import * as React from "react";
import { SVGProps } from "react";

const SvgArrowCircleDown = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.31 12.414 13 13.664V8a1 1 0 1 0-2 0v5.586l-1.293-1.293a.999.999 0 1 0-1.414 1.414l3 3a.996.996 0 0 0 1.084.217c.06-.025.109-.068.162-.104.05-.033.107-.053.152-.096l3-2.863a1 1 0 0 0-1.381-1.447ZM12 20c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8Zm0-18C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2Z"
    />
  </svg>
);

export default SvgArrowCircleDown;
