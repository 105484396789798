import { TabsProps as ReactTabsProps, TabPaneProps } from "rc-tabs";
/**
 * this will be  used to check which size tab to render
 */
export enum tabSizeEnum {
  large = 32,
  medium = 24,
  small = 16,
}

export type TabsProps = ReactTabsProps & {
  tabSize?: keyof typeof tabSizeEnum;
};

export type TabWrapperProps = TabPaneProps & {
  key: number | string;
};

export type TitleProps = {
  title: string;
};
