import * as React from "react";

const SvgPricetag = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M10.56 8.44a1.5 1.5 0 11-2.119 2.12 1.5 1.5 0 012.12-2.12zm-4.726 4.452l6.032 6.033 7.058-7.058-6.032-6.033-7.785-.726.727 7.784zm6.032 8.107c-.465 0-.929-.177-1.284-.53L4.165 14.05a1 1 0 01-.289-.614l-.872-9.344a1.002 1.002 0 011.089-1.088l9.344.872a.995.995 0 01.614.288l6.417 6.417c.343.343.532.799.532 1.285 0 .486-.189.942-.532 1.285l-7.317 7.316a1.81 1.81 0 01-1.285.53z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgPricetag;
