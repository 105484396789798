import * as React from "react";
import { SVGProps } from "react";

const SvgArrowCircleLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 11h-5.664l1.25-1.31a1.001 1.001 0 0 0-1.447-1.38l-2.863 3c-.038.04-.055.09-.086.135-.041.059-.087.113-.114.181a.944.944 0 0 0-.057.283C7.016 11.94 7 11.968 7 12l.001.005a1.005 1.005 0 0 0 .292.703l3 2.999a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L10.414 13H16a1 1 0 0 0 0-2Zm-4 9c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8Zm0-18C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2Z"
    />
  </svg>
);

export default SvgArrowCircleLeft;
