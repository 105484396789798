/** @jsx jsx */
import { FC } from "react";
import { jsx } from "theme-ui";
import { TimeLineCardHeaderProps } from "./types";

const TimelineCard: FC = ({ children }) => {
  return (
    <div
      sx={{
        border: "1px",
        borderStyle: "solid",
        borderColor: "background-neutral",
        borderRadius: 8,
        flexGrow: 1,
        mt: "spacing-xs",
        marginInlineStart: "spacing-xs",
      }}
    >
      {children}
    </div>
  );
};

const TimelineCardHeader: FC<TimeLineCardHeaderProps> = ({ icon, children }) => {
  return (
    <div sx={{ display: "flex" }}>
      {icon}
      <p
        sx={{
          p: 0,
          m: 0,
          color: "content-primary",
          variant: "text.p-large-bold",
        }}
      >
        {children}
      </p>
    </div>
  );
};

const TimelineCardSubHeader: FC = ({ children }) => {
  return (
    <p
      sx={{
        p: 0,
        m: 0,
        variant: "text.p-small-bold",
        color: "content-primary",
      }}
    >
      {children}
    </p>
  );
};

const TimelineCardContent: FC = ({ children }) => {
  return (
    <div
      sx={{
        p: "spacing-s",
        variant: "text.p-small-medium",
        color: "content-secondary",
        ":not(:last-child)": {
          borderBottomWidth: "1px",
          borderBottomStyle: "solid",
          borderBottomColor: "background-quarternary",
        },
      }}
    >
      {children}
    </div>
  );
};

export { TimelineCard, TimelineCardHeader, TimelineCardSubHeader, TimelineCardContent };
