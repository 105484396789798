/** @jsx jsx */
import { FC } from "react";
import { jsx } from "theme-ui";

import { TimelineHeaderProps, TimestampProps } from "./types";

const TimelineHeader = ({ title, action }: TimelineHeaderProps) => {
  return (
    <div sx={{ display: "flex" }}>
      <p
        sx={{
          width: 90,
          wordBreak: "break-word",
          variant: "text.p-large-bold",
        }}
      >
        {title}
      </p>
      <p sx={{ variant: "text.p-large-bold", flexGrow: 1, marginInlineStart: 8 }}>{action}</p>
    </div>
  );
};

const Timestamp: FC<TimestampProps> = ({ time, children }) => {
  return (
    <div sx={{ display: "flex" }}>
      <div
        sx={{
          minWidth: 90,
          maxWidth: 90,
          borderInlineEnd: "2px",
          borderInlineEndStyle: "solid",
          borderInlineEndColor: "background-quarternary",
        }}
      >
        <p
          sx={{
            m: 0,
            p: 0,
            mt: "spacing-xs",
            marginInlineStart: "spacing-xs",
            variant: "text.p-small-bold",
            color: "content-primary",
          }}
        >
          {time}
        </p>
      </div>
      {children}
    </div>
  );
};

const TimePill: FC = ({ children }) => {
  return (
    <div
      sx={{
        width: 90,
        borderInlineEnd: "2px",
        borderInlineEndStyle: "solid",
        borderInlineEndColor: "background-quarternary",
      }}
    >
      <div
        sx={{
          m: 0,
          px: "spacing-s",
          py: "spacing-xxs",
          textAlign: "center",
          display: "inline-block",
          variant: "text.p-small-bold",
          borderRadius: 16,
          color: "primary-light-100",
          backgroundColor: "secondary",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export { TimelineHeader, Timestamp, TimePill };
