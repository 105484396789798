import * as React from "react";

const SvgUtilization = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path d="M20.38 8.57l-1.23 1.85a8 8 0 01-.22 7.58H5.07A8 8 0 0115.58 6.85l1.85-1.23A10 10 0 003.35 19a2 2 0 001.72 1h13.85a2 2 0 001.74-1 10 10 0 00-.27-10.44l-.01.01z" />
      <path d="M10.59 15.41a1.998 1.998 0 002.83 0l5.66-8.49-8.49 5.66a1.999 1.999 0 000 2.83z" />
    </svg>
  );
};

export default SvgUtilization;
