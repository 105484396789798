import * as React from "react";

const SvgEmail = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M19 18H5c-.551 0-1-.448-1-1V7.25l7.4 5.55a.995.995 0 001.2 0L20 7.25V17c0 .552-.449 1-1 1zm-.667-12L12 10.75 5.667 6h12.666zM19 4H5C3.346 4 2 5.346 2 7v10c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3V7c0-1.654-1.346-3-3-3z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgEmail;
