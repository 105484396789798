import * as React from "react";

const SvgDollar = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path d="M8 16a1 1 0 10-2 0 5.006 5.006 0 005 5v1a1 1 0 002 0v-1a5 5 0 100-10V5a3 3 0 013 3 1 1 0 002 0 5.006 5.006 0 00-5-5V2a1 1 0 00-2 0v1a5 5 0 100 10v6a3 3 0 01-3-3zm5-3a3 3 0 010 6v-6zM8 8a3 3 0 013-3v6a3 3 0 01-3-3z" />
    </svg>
  );
};

export default SvgDollar;
