import * as React from "react";

const SvgPhone = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M6.6 4A2.602 2.602 0 004 6.6C4 13.988 10.011 20 17.4 20a2.603 2.603 0 002.58-2.928l-4.62-1.06c-.093.176-.194.367-.286.545-.456.87-.786 1.502-1.628 1.163-3.306-1.16-6.005-3.86-7.18-7.206-.357-.785.323-1.141 1.185-1.591l.537-.281-1.06-4.621A2.604 2.604 0 006.6 4zm10.8 18C8.908 22 2 15.092 2 6.6a4.605 4.605 0 016.091-4.352c.325.113.57.386.647.722l1.37 5.964a.997.997 0 01-.254.916c-.136.142-.139.145-1.379.794a9.949 9.949 0 004.88 4.883c.65-1.241.653-1.245.795-1.381a1.01 1.01 0 01.916-.253l5.965 1.369a.998.998 0 01.72.646A4.553 4.553 0 0122 17.4c0 2.537-2.064 4.6-4.6 4.6z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgPhone;
