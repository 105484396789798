import * as React from "react";

const SvgAlertTriangleFilled = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_715_2444"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={3}
        y={4}
        width={18}
        height={16}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 9C11 8.448 11.448 8 12 8C12.552 8 13 8.448 13 9V13C13 13.552 12.552 14 12 14C11.448 14 11 13.552 11 13V9ZM11 16C11 15.448 11.448 15 12 15C12.552 15 13 15.448 13 16C13 16.552 12.552 17 12 17C11.448 17 11 16.552 11 16ZM20.6406 16.0446L14.3634 5.33389C13.8733 4.49853 12.9896 4 11.9996 4C11.0096 4 10.1259 4.49853 9.63662 5.33389L3.35936 16.0446C2.89381 16.8387 2.8799 17.7937 3.32173 18.5987C3.79547 19.4636 4.71594 20 5.72233 20H18.2768C19.2841 20 20.2037 19.4636 20.6783 18.5987C21.1201 17.7937 21.1062 16.8387 20.6406 16.0446Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_715_2444)">
        <rect width={24} height={24} fill="#F38705" />
      </g>
    </svg>
  );
};

export default SvgAlertTriangleFilled;
