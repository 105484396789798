import * as React from "react";

const SvgRecentActors = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 44 28" {...props}>
      <path
        fillRule="evenodd"
        d="M3 28C2.2 28 1.5 27.7 0.9 27.1C0.3 26.5 0 25.8 0 25V3C0 2.2 0.3 1.5 0.9 0.9C1.5 0.3 2.2 0 3 0H25C25.8 0 26.5 0.3 27.1 0.9C27.7 1.5 28 2.2 28 3V25C28 25.8 27.7 26.5 27.1 27.1C26.5 27.7 25.8 28 25 28H3ZM3 22.4C4.63333 21.3333 6.39167 20.5333 8.275 20C10.1583 19.4667 12.0667 19.2 14 19.2C15.9333 19.2 17.8417 19.4667 19.725 20C21.6083 20.5333 23.3667 21.3333 25 22.4V3H3V22.4ZM14 22.2C12.2333 22.2 10.55 22.425 8.95 22.875C7.35 23.325 5.86667 24.0333 4.5 25H23.5C22.0667 24.0333 20.5333 23.325 18.9 22.875C17.2667 22.425 15.6333 22.2 14 22.2ZM14 16.55C12.4667 16.55 11.1583 16.0083 10.075 14.925C8.99167 13.8417 8.45 12.5333 8.45 11C8.45 9.46667 8.99167 8.15833 10.075 7.075C11.1583 5.99167 12.4667 5.45 14 5.45C15.5333 5.45 16.8417 5.99167 17.925 7.075C19.0083 8.15833 19.55 9.46667 19.55 11C19.55 12.5333 19.0083 13.8417 17.925 14.925C16.8417 16.0083 15.5333 16.55 14 16.55ZM14 13.9C14.8 13.9 15.4833 13.625 16.05 13.075C16.6167 12.525 16.9 11.8333 16.9 11C16.9 10.2 16.6167 9.51667 16.05 8.95C15.4833 8.38333 14.8 8.1 14 8.1C13.1667 8.1 12.475 8.38333 11.925 8.95C11.375 9.51667 11.1 10.2 11.1 11C11.1 11.8333 11.375 12.525 11.925 13.075C12.475 13.625 13.1667 13.9 14 13.9ZM33 28V0H36V28H33ZM41 28V0H44V28H41Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgRecentActors;
