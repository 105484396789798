import * as React from "react";

const SvgCaptainAssign = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M15.04 5.5C15.04 4.12 12.34 3 9.02 3c-3.32 0-6.018 1.12-6.018 2.5 0 .75.792 1.42 2.056 1.88a3.994 3.994 0 001.125 3.448 4.018 4.018 0 005.674 0A3.994 3.994 0 0013.033 8a4.2 4.2 0 00-.06-.62c1.274-.46 2.066-1.13 2.066-1.88zM9.02 10a2.01 2.01 0 01-1.418-.586A1.997 1.997 0 017.015 8a.608.608 0 010-.14c.664.095 1.335.141 2.006.14.671.002 1.342-.045 2.006-.14a.607.607 0 010 .14c0 .53-.211 1.04-.587 1.414A2.009 2.009 0 019.02 10zm0-4a9.857 9.857 0 01-3.16-.46l.09-.11A9.948 9.948 0 019.022 5a10.29 10.29 0 013.08.43l.08.11A9.806 9.806 0 019.02 6zm9.68 8.66l3.008-4a.9.9 0 00-.06-1.41 1.317 1.317 0 00-1.655.09l-2.227 3.15-.742-.71a1.316 1.316 0 00-1.655-.08.902.902 0 00-.323 1.08.9.9 0 00.233.33l1.675 1.56c.238.216.55.334.873.33.323 0 .634-.121.872-.34zM14.035 21h2.006a6.99 6.99 0 00-2.056-4.95 7.032 7.032 0 00-9.93 0A6.99 6.99 0 002 21h2.006c0-1.326.528-2.598 1.469-3.535a5.023 5.023 0 017.092 0A4.992 4.992 0 0114.037 21zM8.18 19h1.675a1.005 1.005 0 011.003 1 .998.998 0 01-1.003 1H8.179a1.005 1.005 0 01-1.003-1 .998.998 0 011.003-1z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgCaptainAssign;
