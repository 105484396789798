import * as React from "react";

const SvgPersonDone = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M10 5c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2zm0 6c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm11.656-6.754a1 1 0 00-1.41.098l-1.867 2.145-.634-.71a.999.999 0 10-1.49 1.335l1.39 1.553a1 1 0 00.744.333h.007c.287-.002.56-.127.748-.343l2.611-3a1 1 0 00-.099-1.411zM3 20c0-3.86 3.141-7 7-7s7 3.14 7 7a1 1 0 11-2 0c0-2.757-2.243-5-5-5s-5 2.243-5 5a1 1 0 11-2 0z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgPersonDone;
