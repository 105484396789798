import * as React from "react";

const SvgReassign = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M18 10a1.001 1.001 0 00-2 0 1.001 1.001 0 002 0zm2 0c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm-9-3c0-1.103-.897-2-2-2s-2 .897-2 2 .897 2 2 2 2-.897 2-2zm2 0c0 2.206-1.794 4-4 4S5 9.206 5 7s1.794-4 4-4 4 1.794 4 4zm.94 8.046A4.994 4.994 0 0117 14c2.757 0 5 2.243 5 5a1 1 0 11-2 0c0-1.654-1.346-3-3-3-.683 0-1.332.234-1.856.649.544.996.856 2.138.856 3.351a1 1 0 11-2 0c0-2.757-2.243-5-5-5s-5 2.243-5 5a1 1 0 11-2 0c0-3.86 3.141-7 7-7 1.927 0 3.673.783 4.94 2.046z"
        clipRule="evenodd"
      />
      <path d="M15.94 2.771L15.634.83l-2.226 3.004 3.041 2.175-.305-1.942c2.18-.343 4.225 1.12 4.562 3.262a3.795 3.795 0 01-.175 1.885l1.11.794c.388-.877.543-1.87.383-2.886-.45-2.862-3.173-4.808-6.084-4.35z" />
    </svg>
  );
};

export default SvgReassign;
