import * as React from "react";

const SvgRefresh = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M20.303 13.426a1.001 1.001 0 00-1.256.65C18.11 17.022 15.352 19 12.184 19 8.222 19 5 15.86 5 12s3.222-7 7.184-7c1.728 0 3.358.602 4.645 1.67l-2.166-.357a.99.99 0 00-1.15.823.999.999 0 00.824 1.149l4.246.702a1.025 1.025 0 00.507-.048c.037-.014.067-.04.103-.06.066-.033.135-.063.192-.111.036-.03.06-.071.09-.105.047-.05.097-.096.132-.156.025-.043.035-.094.054-.142.024-.06.056-.114.068-.18l.754-4a1 1 0 10-1.966-.37l-.272 1.44A9.23 9.23 0 0012.185 3C7.12 3 3 7.037 3 12s4.12 9 9.184 9c4.042 0 7.566-2.54 8.77-6.318a1.001 1.001 0 00-.65-1.256"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgRefresh;
