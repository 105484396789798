import * as React from "react";

const SvgCaptainAdd = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M4.95 8a4.21 4.21 0 01.09-.62C3.78 6.92 2.95 6.25 2.95 5.5 2.95 4.12 5.71 3 9.01 3s6 1.12 6 2.5c0 .75-.79 1.42-2.06 1.88a4.22 4.22 0 010 .62 4 4 0 01-8 0zm2.676 1.414A2 2 0 009.04 10H9a2 2 0 002.04-2 .618.618 0 000-.14 14.06 14.06 0 01-2 .14 13.91 13.91 0 01-2-.14.61.61 0 000 .14 2 2 0 00.586 1.414zM12.12 5.43A10.25 10.25 0 009.04 5H9a10 10 0 00-3.05.43l-.11.11A9.87 9.87 0 009 6a9.78 9.78 0 003.2-.46l-.08-.11zM4.05 16.05A7 7 0 019 14a7 7 0 016.95 7h-2a5 5 0 10-10 0H2a7 7 0 012.05-4.95zM20.95 11h-2V9a1 1 0 00-2 0v2h-2a1 1 0 000 2h2v2a1 1 0 102 0v-2h2a1 1 0 000-2zM8.16 19h1.67a1 1 0 010 2H8.16a1 1 0 010-2z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgCaptainAdd;
