import * as React from "react";

const SvgMenu = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M3.948 6h16.103c.522 0 .95.427.95.949v.102a.952.952 0 01-.95.949H3.948A.95.95 0 013 7.051v-.102A.95.95 0 013.948 6zm16.103 5H3.948a.95.95 0 00-.948.949v.102a.95.95 0 00.948.949h16.103a.952.952 0 00.95-.949v-.102a.952.952 0 00-.95-.949zm0 5H3.948a.95.95 0 00-.948.949v.102a.95.95 0 00.948.949h16.103a.952.952 0 00.95-.949v-.102a.952.952 0 00-.95-.949z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgMenu;
