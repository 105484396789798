const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) {
    return "";
  }
  return `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)})`;
};

const _interpolateColor = (startColor: number[], endColor: number[], factor: number = 0.5) => {
  const result = [...startColor];
  for (let i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (endColor[i] - startColor[i]));
  }

  return result;
};

export const interpolateColors = (startColor: string, endColor: string, steps: number) => {
  const stepFactor = 1 / (steps - 1);
  const interpolatedColorArray: number[][] = [];

  const startColorInRgb = hexToRgb(startColor);
  const endColorInRgb = hexToRgb(endColor);

  const startColorArray = startColorInRgb.match(/\d+/g)?.map(Number) ?? [];
  const endColorArray = endColorInRgb.match(/\d+/g)?.map(Number) ?? [];
  for (let i = 0; i < steps; i++) {
    interpolatedColorArray.push(_interpolateColor(startColorArray, endColorArray, stepFactor * i));
  }

  return interpolatedColorArray;
};
