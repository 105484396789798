import * as React from "react";

const SvgMap = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M19 17.454l-2-.898V6.546l2 .898v10.01zm-6-10.01l2-.898v10.01l-2 .898V7.444zm-4-.898l2 .898v10.01l-2-.898V6.546zm-4 .898l2-.898v10.01l-2 .898V7.444zm15.41-1.558l-4-1.798c-.015-.007-.031-.008-.046-.014a1.106 1.106 0 00-.36-.067c-.044 0-.087.001-.131.006-.037.004-.072.013-.108.022a1.21 1.21 0 00-.13.04c-.014.005-.031.006-.045.013L12 5.701 8.41 4.088c-.015-.007-.031-.008-.046-.014a1.106 1.106 0 00-.36-.067c-.044 0-.087.001-.131.006-.037.004-.072.013-.108.022a1.21 1.21 0 00-.13.04c-.014.005-.031.006-.045.013l-4 1.798a1 1 0 00-.59.912V19a1.002 1.002 0 001.41.912L8 18.3l3.59 1.613c.015.007.031.004.047.01A.974.974 0 0012 20a.974.974 0 00.363-.078c.015-.006.033-.003.047-.01L16 18.3l3.59 1.613A.998.998 0 0021 19V6.798a1 1 0 00-.59-.912z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgMap;
