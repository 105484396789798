import * as React from "react";

const SvgSupply = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M5.74982 21.386C5.46648 21.386 5.22882 21.29 5.03682 21.098C4.84548 20.9067 4.74982 20.6693 4.74982 20.386V18.336C4.44982 18.0027 4.20815 17.6317 4.02482 17.223C3.84148 16.815 3.74982 16.3693 3.74982 15.886V6.386C3.74982 5.00267 4.39148 3.99 5.67482 3.348C6.95815 2.70667 8.98315 2.386 11.7498 2.386C14.6165 2.386 16.6665 2.69433 17.8998 3.311C19.1332 3.92767 19.7498 4.95267 19.7498 6.386V15.886C19.7498 16.3693 19.6582 16.815 19.4748 17.223C19.2915 17.6317 19.0498 18.0027 18.7498 18.336V20.386C18.7498 20.6693 18.6538 20.9067 18.4618 21.098C18.2705 21.29 18.0332 21.386 17.7498 21.386H16.7498C16.4665 21.386 16.2292 21.29 16.0378 21.098C15.8458 20.9067 15.7498 20.6693 15.7498 20.386V19.386H7.74982V20.386C7.74982 20.6693 7.65415 20.9067 7.46282 21.098C7.27082 21.29 7.03315 21.386 6.74982 21.386H5.74982ZM5.74982 10.386H17.7498V7.386H5.74982V10.386ZM8.24982 16.386C8.66648 16.386 9.02048 16.24 9.31182 15.948C9.60382 15.6567 9.74982 15.3027 9.74982 14.886C9.74982 14.4693 9.60382 14.1153 9.31182 13.824C9.02048 13.532 8.66648 13.386 8.24982 13.386C7.83315 13.386 7.47915 13.532 7.18782 13.824C6.89582 14.1153 6.74982 14.4693 6.74982 14.886C6.74982 15.3027 6.89582 15.6567 7.18782 15.948C7.47915 16.24 7.83315 16.386 8.24982 16.386ZM15.2498 16.386C15.6665 16.386 16.0205 16.24 16.3118 15.948C16.6038 15.6567 16.7498 15.3027 16.7498 14.886C16.7498 14.4693 16.6038 14.1153 16.3118 13.824C16.0205 13.532 15.6665 13.386 15.2498 13.386C14.8331 13.386 14.4791 13.532 14.1878 13.824C13.8958 14.1153 13.7498 14.4693 13.7498 14.886C13.7498 15.3027 13.8958 15.6567 14.1878 15.948C14.4791 16.24 14.8331 16.386 15.2498 16.386Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgSupply;
