import * as React from "react";

const SvgStation = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path d="M5 20.816v.684a1.5 1.5 0 003 0V21h8v.5a1.5 1.5 0 103 0v-.684A3 3 0 0021 18V4a3 3 0 00-3-3H6a3 3 0 00-3 3v14a3 3 0 002 2.816zM5 4a1 1 0 011-1h12a1 1 0 011 1v1H5V4zm0 3h14v6H5V7zm0 8h14v3a1 1 0 01-1 1H6a1 1 0 01-1-1v-3zm1 2a1 1 0 112 0 1 1 0 01-2 0zm10 0a1 1 0 112 0 1 1 0 01-2 0z" />
    </svg>
  );
};

export default SvgStation;
