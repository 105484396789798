import * as React from "react";

const SvgUpload = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M5 4h14c.55 0 1 .45 1 1v2c0 .55-.45 1-1 1s-1-.45-1-1V6H6v1c0 .55-.45 1-1 1s-1-.45-1-1V5c0-.55.45-1 1-1zm3.001 10a1 1 0 01-.601-1.8l4-3a1 1 0 011.175-.018l4 2.814a1 1 0 11-1.15 1.636l-2.428-1.708c.002.025.003.05.003.076v8a1 1 0 11-2 0v-8l-2.4 1.8c-.18.135-.39.2-.599.2z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgUpload;
