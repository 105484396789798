/** @jsx jsx */
import { jsx, ThemeUIStyleObject } from "theme-ui";
import { PlusIcon, MinusIcon } from "@swvl/icons";

type ZoomControlsProps = {
  map: google.maps.Map;
};

const buttonCssReset: ThemeUIStyleObject = {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  outline: 0,
  p: 0,
  borderColor: "transparent",
  textDecoration: "none",
  boxSizing: "border-box",
  cursor: "pointer",
  userSelect: "none",
  backgroundColor: "primary-light-100",
  color: "content-primary",
  height: "32px",
  width: "32px",
  transition: "background-color .25s",

  "&:hover": {
    bg: "secondary-light-90",
  },
  "&:active": {
    bg: "secondary-light-80",
  },

  svg: {
    width: 20,
    height: 20,
  },
};

const ZoomControls = ({ map }: ZoomControlsProps) => {
  return (
    <div
      sx={{
        position: "absolute",
        bottom: "spacing-l",
        right: "spacing-l",
        boxShadow: "menu",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        borderRadius: 12,
      }}
    >
      <button sx={{ ...buttonCssReset }} onClick={() => map.setZoom(map.getZoom() + 1)}>
        <PlusIcon width={20} height={20} />
      </button>
      <button sx={{ ...buttonCssReset }} onClick={() => map.setZoom(map.getZoom() - 1)}>
        <MinusIcon width={20} height={20} />
      </button>
    </div>
  );
};

export default ZoomControls;
