import * as React from "react";

const SvgArrowIosRight = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M10 19a1 1 0 01-.768-1.64l4.476-5.371-4.316-5.362a1 1 0 011.56-1.254l4.828 6a1 1 0 01-.011 1.267l-5 6a1 1 0 01-.77.36"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgArrowIosRight;
