import * as React from "react";

const SvgInfoFilled = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22Z"
        fill="#2BA7F0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 16C11 16.552 11.448 17 12 17C12.552 17 13 16.552 13 16V11C13 10.448 12.552 10 12 10C11.448 10 11 10.448 11 11V16ZM11 8C11 8.552 11.448 9 12 9C12.552 9 13 8.552 13 8C13 7.448 12.552 7 12 7C11.448 7 11 7.448 11 8Z"
        fill="white"
      />
    </svg>
  );
};

export default SvgInfoFilled;
