import * as React from "react";

const SvgChevronRight = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M10.5 17a.999.999 0 01-.707-1.707l3.305-3.305-3.18-3.293a1 1 0 011.439-1.39l3.862 4a1 1 0 01-.012 1.402l-4 4A.997.997 0 0110.5 17"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgChevronRight;
