import * as React from "react";

const SvgGenerateRides = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M13 16.25V7c0-1.1.9-2 2-2s2 .9 2 2v4.15a5.265 5.265 0 012-.097V7c0-2.21-1.79-4-4-4s-4 1.79-4 4v10c0 1.1-.9 2-2 2s-2-.9-2-2V8.82C8.16 8.4 9 7.3 9 6c0-1.66-1.34-3-3-3S3 4.34 3 6c0 1.3.84 2.4 2 2.82V17c0 2.21 1.79 4 4 4s4-1.79 4-4v-.75zM5 6c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1z"
        clipRule="evenodd"
      />
      <path d="M22.916 17.688l-.988-.747c.018-.144.037-.288.037-.441 0-.153-.01-.297-.037-.441l.979-.747a.23.23 0 00.055-.288l-.924-1.557c-.055-.099-.175-.135-.286-.099l-1.145.45a3.18 3.18 0 00-.785-.441l-.176-1.188c-.009-.108-.11-.189-.221-.189h-1.847a.236.236 0 00-.231.189l-.176 1.188a3.716 3.716 0 00-.785.441l-1.145-.45a.245.245 0 00-.286.099l-.924 1.557a.219.219 0 00.056.288l.979.747a3.423 3.423 0 000 .882l-.98.747a.23.23 0 00-.055.288l.924 1.557c.055.099.175.135.286.099l1.145-.45c.24.18.499.333.785.441l.176 1.188c.018.108.11.189.23.189h1.848c.11 0 .212-.081.23-.189l.176-1.188c.277-.117.545-.261.776-.441l1.154.45c.102.036.222 0 .287-.099l.923-1.557a.23.23 0 00-.055-.288zm-4.415.162c-.766 0-1.385-.603-1.385-1.35 0-.747.619-1.35 1.385-1.35.767 0 1.386.603 1.386 1.35 0 .747-.62 1.35-1.386 1.35z" />
    </svg>
  );
};

export default SvgGenerateRides;
