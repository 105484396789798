export default {
  fonts: {
    body: "'Noto Sans Arabic', sans-serif",
    heading: "'Noto Sans Arabic', sans-serif",
  },
  fontWeights: {
    bold: 700,
    medium: 500,
    regular: 400,
    light: 300,
  },
  text: {
    display: {
      fontSize: "6rem",
      fontWeight: "bold",
      lineHeight: "9rem",
    },
    h1: {
      fontSize: "4.5rem",
      fontWeight: "bold",
      lineHeight: "6.75rem",
    },
    h2: {
      fontSize: "4rem",
      fontWeight: "bold",
      lineHeight: "6rem",
    },
    h3: {
      fontSize: "3rem",
      fontWeight: "bold",
      lineHeight: "4.5rem",
    },
    h4: {
      fontSize: "2rem",
      fontWeight: "bold",
      lineHeight: "3rem",
    },
    h5: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      lineHeight: "2.25rem",
    },
    "p-large": {
      fontSize: "1.125rem",
      fontWeight: "regular",
      lineHeight: "1.75rem",
    },
    "p-large-medium": {
      fontSize: "1.125rem",
      fontWeight: "medium",
      lineHeight: "1.75rem",
    },
    "p-large-bold": {
      fontSize: "1.125rem",
      fontWeight: "bold",
      lineHeight: "1.75rem",
    },
    "p-medium": {
      fontSize: "1rem",
      fontWeight: "regular",
      lineHeight: "1.5rem",
    },
    "p-medium-medium": {
      fontSize: "1rem",
      fontWeight: "medium",
      lineHeight: "1.5rem",
    },
    "p-medium-bold": {
      fontSize: "1rem",
      fontWeight: "bold",
      lineHeight: "1.5rem",
    },
    "p-small": {
      fontSize: "0.875rem",
      fontWeight: "regular",
      lineHeight: "1.375rem",
    },
    "p-small-medium": {
      fontSize: "0.875rem",
      fontWeight: "medium",
      lineHeight: "1.375rem",
    },
    "p-small-bold": {
      fontSize: "0.875rem",
      fontWeight: "bold",
      lineHeight: "1.375rem",
    },
    "p-x-small": {
      fontSize: "0.75rem",
      fontWeight: "regular",
      lineHeight: "1.25rem",
    },
    "p-x-small-medium": {
      fontSize: "0.75rem",
      fontWeight: "medium",
      lineHeight: "1.25rem",
    },
    "p-x-small-bold": {
      fontSize: "0.75rem",
      fontWeight: "bold",
      lineHeight: "1.25rem",
    },
    "link-text": {
      fontSize: "1rem",
      fontWeight: "medium",
      lineHeight: "1.25rem",
      textDecorationLine: "underline",
    },
    "link-text-small": {
      fontSize: "0.875rem",
      fontWeight: "medium",
      lineHeight: "1.25rem",
      textDecorationLine: "underline",
    },
    "button-text": {
      fontSize: "1rem",
      fontWeight: "bold",
      lineHeight: "1.25rem",
    },
  },
};
