import * as React from "react";

const SvgPlanGeneration = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.8" clipPath="url(#clip0_1564_2178)">
        <path
          d="M15.309 18.9C14.349 19.9 13.249 20.93 11.999 22C6.66902 17.45 3.99902 13.52 3.99902 10.2C3.99902 5.22 7.79902 2 11.999 2C16.189 2 19.989 5.21 19.999 10.17L22.089 8.08L23.499 9.5L18.999 14L14.499 9.5L15.909 8.09L17.999 10.17C17.989 6.55 15.339 4 11.999 4C8.64902 4 5.99902 6.57 5.99902 10.2C5.99902 12.54 7.94902 15.64 11.999 19.34C12.639 18.75 13.229 18.18 13.769 17.63C13.599 17.29 13.499 16.91 13.499 16.51C13.499 15.13 14.619 14.01 15.999 14.01C17.379 14.01 18.499 15.13 18.499 16.51C18.499 17.89 17.379 19 15.999 19C15.759 19 15.529 18.97 15.309 18.9Z"
          fill="#181818"
        />
      </g>
      <defs>
        <clipPath id="clip0_1564_2178">
          <rect width="24" height="24" fill="white" transform="translate(-0.000976562)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgPlanGeneration;
