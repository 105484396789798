import * as React from "react";

const SvgDownload = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M11.003 12.076A1.06 1.06 0 0111 12V4a1 1 0 112 0v8l2.4-1.8a1 1 0 111.2 1.6l-4 3a1 1 0 01-1.175.018l-4-2.814a1 1 0 111.15-1.636l2.428 1.708zM6 17v1h12v-1c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1H5c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgDownload;
