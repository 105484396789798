import * as React from "react";

const SvgPersonFilled = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={20} cy={20} r={20} fill="#6589FB" />
      <ellipse cx={20} cy={13.3333} rx={6.66667} ry={6.66667} fill="white" />
      <ellipse cx={20} cy={38.3333} rx={15} ry={15} fill="white" />
    </svg>
  );
};

export default SvgPersonFilled;
