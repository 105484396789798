import * as React from "react";

const SvgFlip2 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M7.207 9.707a.999.999 0 01-1.414 0l-3-3a.999.999 0 010-1.414l3-3a.997.997 0 011.414 0 .999.999 0 010 1.414L5.914 5H17.91c1.98 0 3.59 1.583 3.59 3.529V11a1 1 0 11-2 0V8.529C19.5 7.686 18.787 7 17.91 7H5.914l1.293 1.293a.999.999 0 010 1.414zM18.086 19H6.09c-1.98 0-3.59-1.583-3.59-3.529V13a1 1 0 112 0v2.471c0 .842.713 1.529 1.59 1.529h11.996l-1.293-1.293a1 1 0 011.414-1.414l3 3a.999.999 0 010 1.414l-3 3a.999.999 0 11-1.414-1.414L18.086 19z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgFlip2;
