import * as React from "react";

const SvgArrowDown = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M18.768 13.36a1 1 0 00-1.408-.128L13 16.865V5a1 1 0 00-2 0v11.865l-4.36-3.633a1 1 0 10-1.28 1.536l6 5c.047.04.103.059.155.089.041.024.077.052.122.07A.992.992 0 0012 20a.992.992 0 00.363-.073c.045-.018.081-.046.122-.07.052-.03.108-.049.155-.089l6-5a1 1 0 00.128-1.408"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgArrowDown;
