/** @jsx jsx */
import { jsx } from "theme-ui";
import { FC } from "react";
import { ArrowSDownIcon } from "@swvl/icons";

import { AccordionHeaderProps } from "./types";

const StyledAccordion: FC = ({ children }) => (
  <div
    sx={{
      borderRadius: "6px",
      border: "1px solid",
      borderColor: "border",
      ".Collapsible__trigger": {
        py: "spacing-s",
        cursor: "pointer",
      },
    }}
  >
    {children}
  </div>
);

export const AccordionHeader: FC<AccordionHeaderProps> = ({ title, isExpanded }) => (
  <div
    sx={{
      display: "flex",
      px: "spacing-m",
      color: "content-primary",
      justifyContent: "space-between",
      variant: "text.p-medium-medium",
    }}
  >
    <p
      sx={{
        margin: 0,
        whiteSpace: "nowrap",
        overflow: "hidden",
        "text-overflow": "ellipsis",
        paddingInlineEnd: "spacing-s",
      }}
      title={title}
    >
      {title}
    </p>
    <ArrowSDownIcon
      sx={{
        flexShrink: 0,
        transform: isExpanded ? "rotate(180deg)" : null,
        transition: "transform .2s",
      }}
    />
  </div>
);

export default StyledAccordion;
