import * as React from "react";

const SvgSwap = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M17 8H4a1 1 0 110-2h13.082l-1.558-1.21a1 1 0 011.227-1.58l3.862 3A1 1 0 0120.6 7.8l-4 3a.998.998 0 01-1.4-.2 1 1 0 01.2-1.4L17 8zM7 15h13a1 1 0 110 2H6.918l1.558 1.21a1 1 0 01-1.227 1.58l-3.862-3A1 1 0 013.4 15.2l4-3a1 1 0 111.2 1.6L7 15z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgSwap;
