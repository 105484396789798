import * as React from "react";

const SvgEye = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M12 13.5c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5zm0-5c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5zm.22 8.498c-4.306.1-7.115-3.583-8.047-5.002 1.026-1.605 3.61-4.891 7.608-4.993 4.289-.11 7.114 3.583 8.046 5.002-1.025 1.605-3.61 4.89-7.607 4.993zm9.648-5.495c-.638-1.112-4.162-6.686-10.138-6.5-5.528.14-8.743 5.01-9.597 6.5a.999.999 0 000 .995C2.762 13.595 6.162 19 12.025 19l.246-.003c5.527-.141 8.743-5.01 9.597-6.5a1.004 1.004 0 000-.994z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgEye;
