import * as React from "react";

const SvgSync = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M21.683 10.326l-.021.048a.641.641 0 00-.018.052c-.009.028-.017.056-.032.081a.8.8 0 01-.107.132l-.033.035c-.013.013-.023.026-.034.04-.015.02-.03.038-.048.054a.944.944 0 01-.186.11l-.013.006a.368.368 0 00-.04.023c-.018.012-.037.024-.057.031a.982.982 0 01-.507.048l-4.25-.705a1 1 0 11.327-1.972l1.644.272C17.063 6.395 14.686 5 12.07 5 9.11 5 6.504 6.718 5.427 9.375a.998.998 0 01-1.302.552.999.999 0 01-.551-1.302C4.958 5.208 8.293 3 12.07 3c3.412 0 6.513 1.87 8.078 4.788l.369-1.973a1 1 0 011.966.37l-.75 4a.607.607 0 01-.05.141zm-3.11 4.3a1 1 0 111.854.75C19.043 18.791 15.707 21 11.93 21c-3.412 0-6.513-1.87-8.078-4.788l-.37 1.972a.998.998 0 01-1.167.798.999.999 0 01-.798-1.167l.749-4c.007-.04.024-.074.04-.109a.76.76 0 00.05-.123.539.539 0 01.056-.128.33.33 0 01.053-.06c.012-.01.024-.022.036-.035l.026-.03a.799.799 0 01.155-.153.078.078 0 01.02-.01.09.09 0 00.017-.007.949.949 0 01.316-.137.078.078 0 01.021 0h.019c.11-.019.22-.03.338-.01l4.25.706a1 1 0 11-.327 1.972l-1.644-.272C6.938 17.605 9.315 19 11.93 19c2.959 0 5.566-1.718 6.643-4.375z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgSync;
