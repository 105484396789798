import * as React from "react";

const SvgUpdown = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path d="M12.35 7.155a.472.472 0 00-.7 0l-2.274 2.73a.455.455 0 00.35.746h4.548a.454.454 0 00.35-.747L12.35 7.155zm1.924 6.205H9.726a.455.455 0 00-.35.747l2.274 2.729a.454.454 0 00.7 0l2.274-2.73a.455.455 0 00-.35-.746z" />
    </svg>
  );
};

export default SvgUpdown;
