import * as React from "react";

const SvgDiagonalarrowrightup = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M17.993 7.049a1 1 0 00-.994-1L9.006 6H9a1 1 0 00-.006 2l5.558.036-8.259 8.258a.999.999 0 101.414 1.414l8.288-8.288L16 15A1 1 0 0017 16h.001a1 1 0 001-1.001l-.008-7.95z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgDiagonalarrowrightup;
