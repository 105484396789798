import * as React from "react";

const SvgDemand = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M3 21V7H9V5L12 2L15 5V11H21V21H3ZM5 19H7V17H5V19ZM5 15H7V13H5V15ZM5 11H7V9H5V11ZM11 19H13V17H11V19ZM11 15H13V13H11V15ZM11 11H13V9H11V11ZM11 7H13V5H11V7ZM17 19H19V17H17V19ZM17 15H19V13H17V15Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgDemand;
