import * as React from "react";

const SvgPrivateBus = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M6 19C5.16667 19 4.45833 18.7083 3.875 18.125C3.29167 17.5417 3 16.8333 3 16H1V7C1 6.45 1.196 5.97933 1.588 5.588C1.97933 5.196 2.45 5 3 5H17L23 11V16H21C21 16.8333 20.7083 17.5417 20.125 18.125C19.5417 18.7083 18.8333 19 18 19C17.1667 19 16.4583 18.7083 15.875 18.125C15.2917 17.5417 15 16.8333 15 16H9C9 16.8333 8.70833 17.5417 8.125 18.125C7.54167 18.7083 6.83333 19 6 19ZM15 10H19L16 7H15V10ZM9 10H13V7H9V10ZM3 10H7V7H3V10ZM6 17.25C6.35 17.25 6.646 17.129 6.888 16.887C7.12933 16.6457 7.25 16.35 7.25 16C7.25 15.65 7.12933 15.3543 6.888 15.113C6.646 14.871 6.35 14.75 6 14.75C5.65 14.75 5.35433 14.871 5.113 15.113C4.871 15.3543 4.75 15.65 4.75 16C4.75 16.35 4.871 16.6457 5.113 16.887C5.35433 17.129 5.65 17.25 6 17.25ZM18 17.25C18.35 17.25 18.646 17.129 18.888 16.887C19.1293 16.6457 19.25 16.35 19.25 16C19.25 15.65 19.1293 15.3543 18.888 15.113C18.646 14.871 18.35 14.75 18 14.75C17.65 14.75 17.3543 14.871 17.113 15.113C16.871 15.3543 16.75 15.65 16.75 16C16.75 16.35 16.871 16.6457 17.113 16.887C17.3543 17.129 17.65 17.25 18 17.25Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgPrivateBus;
