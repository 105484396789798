import React, { FC, useEffect } from "react";
import { createPortal } from "react-dom";

type ControlProps = {
  position?: google.maps.ControlPosition;
  map: google.maps.Map;
  children: React.ReactChild;
  containerStyles?: React.CSSProperties;
};

const CustomMapPanelControl: FC<ControlProps> = (props) => {
  const {
    position = window.google.maps.ControlPosition.BOTTOM_CENTER,
    containerStyles,
    map,
    children,
  } = props;

  const container = document.createElement("div");

  useEffect(() => {
    let currentPushedItemIndex = 0;
    if (map) {
      const controls = map.controls[position];
      controls.push(container);
      currentPushedItemIndex = controls?.getLength() - 1;
    }
    return () => {
      if (map) {
        const controls = map.controls[position];
        controls.removeAt(currentPushedItemIndex);
      }
    };
  }, [map, container]);

  return createPortal(<div style={containerStyles}>{children}</div>, container);
};

export default CustomMapPanelControl;
