import * as React from "react";

const SvgFilter = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M12 6a1 1 0 110-2 1 1 0 010 2zm3-1c0-1.654-1.346-3-3-3S9 3.346 9 5c0 1.302.839 2.401 2 2.815V21a1 1 0 102 0V7.815A2.995 2.995 0 0015 5zM6 18a1 1 0 110-2 1 1 0 010 2zm1-3.815V3a1 1 0 10-2 0v11.185A2.995 2.995 0 003 17c0 1.302.839 2.401 2 2.815V21a1 1 0 102 0v-1.185A2.995 2.995 0 009 17a2.995 2.995 0 00-2-2.815zM18 14a1 1 0 110-2 1 1 0 010 2zm3-1a2.995 2.995 0 00-2-2.815V3a1 1 0 10-2 0v7.185A2.995 2.995 0 0015 13c0 1.302.839 2.401 2 2.815V21a1 1 0 102 0v-5.185A2.995 2.995 0 0021 13z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgFilter;
