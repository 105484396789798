export const isChildInParentView = (child, parent) => {
  if (!child || !parent) return false;
  const childPosition = child.getBoundingClientRect();
  const parentPosition = parent.getBoundingClientRect();
  return (
    childPosition.top >= parentPosition.top &&
    childPosition.left >= parentPosition.left &&
    childPosition.bottom <= parentPosition.bottom &&
    childPosition.right <= parentPosition.right
  );
};

export const timeGenerator = (minuteStep: number = 15) => {
  const hours = ["12", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"];

  const times: Array<string> = [];
  // generating AM hours first
  hours.map((hr) => {
    for (let m = 0; m < 60; m += Math.max(+minuteStep, 1)) {
      const _m = m < 10 ? `0${m}` : m;
      const currTime = `${hr}:${_m} am`;
      times.push(currTime);
    }
  });
  // then generating PM hours
  hours.map((hr) => {
    for (let m = 0; m < 60; m += Math.max(+minuteStep, 1)) {
      const _m = m < 10 ? `0${m}` : m;
      const currTime = `${hr}:${_m} pm`;
      times.push(currTime);
    }
  });
  return times;
};

export const timeGenerator24 = (minuteStep: number = 15) => {
  const hours24 = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
  ];

  const times: Array<string> = [];
  hours24.map((hr) => {
    for (let m = 0; m < 60; m += Math.max(+minuteStep, 1)) {
      const _m = m < 10 ? `0${m}` : m;
      const currTime = `${hr}:${_m}`;
      times.push(currTime);
    }
  });
  return times;
};

export const checkDropDownPosition = (menuWrapperRef, listHeight = 150) =>
  window.innerHeight > menuWrapperRef?.current?.getBoundingClientRect()?.y + listHeight;

export const formatToTwoDigits = (n) => {
  return ("0" + n).slice(-2);
};

export const isValidTime = (time) => {
  // Regular expression to match 24-hour time with leading zeros
  const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
  return regex.test(time);
};
