import * as React from "react";

const SvgWaitingTimeFilled = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 24.6667H25.3333V22C25.3333 21.2627 24.736 20.6667 24 20.6667C23.264 20.6667 22.6667 21.2627 22.6667 22V26C22.6667 26.7373 23.264 27.3333 24 27.3333H28C28.736 27.3333 29.3333 26.7373 29.3333 26C29.3333 25.2627 28.736 24.6667 28 24.6667ZM24 35C19.0373 35 15 30.9627 15 26C15 21.0373 19.0373 17 24 17C28.9627 17 33 21.0373 33 26C33 30.9627 28.9627 35 24 35ZM25.3173 14.0773C25.32 14.0493 25.3333 14.028 25.3333 14V12.6667H26.6667C27.4027 12.6667 28 12.0707 28 11.3333C28 10.596 27.4027 10 26.6667 10H21.3333C20.5973 10 20 10.596 20 11.3333C20 12.0707 20.5973 12.6667 21.3333 12.6667H22.6667V14C22.6667 14.028 22.68 14.0493 22.6827 14.0773C16.684 14.736 12 19.8293 12 26C12 32.6173 17.384 38 24 38C30.616 38 36 32.6173 36 26C36 19.8293 31.316 14.736 25.3173 14.0773Z"
        fill="#231F20"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 24.6666H25.3333V22C25.3333 21.2626 24.736 20.6666 24 20.6666C23.264 20.6666 22.6667 21.2626 22.6667 22V26C22.6667 26.7373 23.264 27.3333 24 27.3333H28C28.736 27.3333 29.3333 26.7373 29.3333 26C29.3333 25.2626 28.736 24.6666 28 24.6666Z"
        fill="#24B2D7"
      />
    </svg>
  );
};

export default SvgWaitingTimeFilled;
