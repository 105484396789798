import * as React from "react";
import { SVGProps } from "react";

const SvgArrowCircleUp = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.707 7.293a.978.978 0 0 0-.322-.215.978.978 0 0 0-.38-.077H12c-.036 0-.066.015-.101.02-.092.01-.184.02-.271.054-.07.029-.126.075-.187.117-.042.03-.091.047-.13.085l-3 2.862a1.001 1.001 0 0 0 1.38 1.447l1.31-1.25V16a1 1 0 0 0 2 0v-5.585l1.293 1.293a1 1 0 0 0 1.415-1.415l-3-3ZM12 20c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8Zm0-18C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2Z"
    />
  </svg>
);

export default SvgArrowCircleUp;
