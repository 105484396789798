/** @jsx jsx */
import { jsx, ThemeUIStyleObject } from "theme-ui";
import { CloseIcon } from "@swvl/icons";

interface CloseButtonProps {
  onClick?: () => void;
}

const buttonCssReset: ThemeUIStyleObject = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  outline: 0,
  borderColor: "transparent",
  textDecoration: "none",
  boxSizing: "border-box",
  cursor: "pointer",
  userSelect: "none",
  backgroundColor: "primary-light-100",
  color: "content-primary",
  height: 32,
  width: 32,
  p: 0,
  transition: "background-color .25s",

  "&:hover": {
    bg: "secondary-light-90",
  },
  "&:active": {
    bg: "secondary-light-80",
  },

  svg: {
    width: 24,
    height: 24,
  },
};

const CloseButton = ({ onClick }: CloseButtonProps) => {
  return (
    <div
      sx={{
        position: "absolute",
        top: "spacing-l",
        right: "spacing-l",
        boxShadow: "menu",
        display: "flex",
        overflow: "hidden",
        borderRadius: 12,
      }}
    >
      <button sx={{ ...buttonCssReset }} onClick={onClick}>
        <CloseIcon />
      </button>
    </div>
  );
};

export default CloseButton;
