import * as React from "react";

const SvgCustomers = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M14 11C14.8333 11 15.5417 10.7083 16.125 10.125C16.7083 9.54167 17 8.83333 17 8C17 7.16667 16.7083 6.45833 16.125 5.875C15.5417 5.29167 14.8333 5 14 5C13.1667 5 12.4583 5.29167 11.875 5.875C11.2917 6.45833 11 7.16667 11 8C11 8.83333 11.2917 9.54167 11.875 10.125C12.4583 10.7083 13.1667 11 14 11ZM8 18C7.45 18 6.97933 17.8043 6.588 17.413C6.196 17.021 6 16.55 6 16V4C6 3.45 6.196 2.979 6.588 2.587C6.97933 2.19567 7.45 2 8 2H20C20.55 2 21.021 2.19567 21.413 2.587C21.8043 2.979 22 3.45 22 4V16C22 16.55 21.8043 17.021 21.413 17.413C21.021 17.8043 20.55 18 20 18H8ZM4 22C3.45 22 2.97933 21.8043 2.588 21.413C2.196 21.021 2 20.55 2 20V6H4V20H18V22H4ZM8 16H20C19.3 15.05 18.421 14.3123 17.363 13.787C16.3043 13.2623 15.1833 13 14 13C12.8167 13 11.696 13.2623 10.638 13.787C9.57933 14.3123 8.7 15.05 8 16Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgCustomers;
