import * as React from "react";

const SvgTime = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <g fill="#181818" opacity={0.8}>
        <path d="M8.333 6.667v5l3.917 2.416.667-1-3.334-2V6.667h-1.25z" />
        <path d="M14.933 10a5.797 5.797 0 01-5.767 6.667 5.797 5.797 0 01-5.833-5.834A5.797 5.797 0 019.167 5c.583 0 1.141.083 1.666.242V3.525a7.3 7.3 0 00-1.666-.192 7.469 7.469 0 00-7.5 7.5c0 4.167 3.333 7.5 7.5 7.5A7.469 7.469 0 0016.617 10h-1.684z" />
        <path d="M16.667 4.167v-2.5H15v2.5h-2.5v1.666H15v2.5h1.667v-2.5h2.5V4.167h-2.5z" />
      </g>
    </svg>
  );
};

export default SvgTime;
