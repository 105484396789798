import * as React from "react";

const SvgMinus = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path fillRule="evenodd" d="M19 13H5a1 1 0 110-2h14a1 1 0 110 2" clipRule="evenodd" />
    </svg>
  );
};

export default SvgMinus;
