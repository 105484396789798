import { useCallback, useEffect, useState } from "react";

const useClickListener = (ref, listBoxRef, selectedItem, generatedTimeList) => {
  const [show, setShow] = useState(false);

  const handleChangeShow = (val: boolean) => {
    setShow(val);
  };

  const handleClickListener = useCallback(
    (ev) => {
      const ele = ref?.current;
      if (ele?.contains(ev.target) || ev.target === ele) {
        const idx = generatedTimeList.indexOf(selectedItem);
        if (show) listBoxRef.current[idx]?.scrollIntoView();
        setShow(true);
      } else setShow(false);
    },
    [ref, listBoxRef, selectedItem, generatedTimeList, show]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickListener);
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      document.removeEventListener("click", () => {});
    };
  }, [handleClickListener]);

  return { show, handleChangeShow };
};

export default useClickListener;
