import * as React from "react";

const SvgBus = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path d="M20 3H4a3 3 0 00-3 3v10a2 2 0 002 2h.142a3.98 3.98 0 007.716 0h2.284a3.981 3.981 0 007.716 0H21a2 2 0 002-2V6a3 3 0 00-3-3zM3 6a1 1 0 011-1h9v4H3V6zm4 13a2 2 0 110-4 2 2 0 010 4zm10 0a2 2 0 110-4 2 2 0 010 4zm4-3h-.142a3.981 3.981 0 00-7.716 0h-2.284a3.981 3.981 0 00-7.716 0H3v-5h18v5zm0-7h-6V5h5a1 1 0 011 1v3z" />
    </svg>
  );
};

export default SvgBus;
