import * as React from "react";

const SvgFlash = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.2341 0.624647C11.1378 0.629372 11.0493 0.679083 10.9942 0.7583L3.80613 11.0702C3.66161 11.2774 3.80986 11.5615 4.06248 11.5615H9.03562L8.4387 19.0377C8.41535 19.3537 8.82298 19.4999 9.00572 19.241L16.1938 8.92967C16.3382 8.7227 16.1904 8.43883 15.9381 8.43835H10.9643L11.5619 0.961554C11.5769 0.773286 11.4227 0.614738 11.2341 0.624647V0.624647Z" />
    </svg>
  );
};

export default SvgFlash;
