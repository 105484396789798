import * as React from "react";

const SvgTrash = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M16.8 17.6a.8.8 0 01-.8.8H8a.8.8 0 01-.8-.8V8.8h9.6v8.8zM10.4 5.862c0-.124.171-.262.4-.262h2.4c.229 0 .4.138.4.262V7.2h-3.2V5.862zM19.2 7.2h-4V5.862c0-1.027-.897-1.862-2-1.862h-2.4c-1.103 0-2 .835-2 1.862V7.2h-4c-.44 0-.8.36-.8.8 0 .44.36.8.8.8h.8v8.8C5.6 18.923 6.677 20 8 20h8c1.323 0 2.4-1.077 2.4-2.4V8.8h.8c.44 0 .8-.36.8-.8 0-.44-.36-.8-.8-.8z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgTrash;
