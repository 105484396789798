import * as React from "react";

const SvgFileUpload = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={22}
      height={28}
      viewBox="0 0 22 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.3}
        d="M12.3333 3.33325H3V24.6666H19V9.99992H12.3333V3.33325ZM16.3333 17.9999H12.3333V23.3333H9.66667V17.9999H5.66667L11.0133 12.6666L16.3333 17.9999Z"
        fill="black"
      />
      <path
        d="M13.6666 0.666626H2.99992C1.53325 0.666626 0.346586 1.86663 0.346586 3.33329L0.333252 24.6666C0.333252 26.1333 1.51992 27.3333 2.98659 27.3333H18.9999C20.4666 27.3333 21.6666 26.1333 21.6666 24.6666V8.66663L13.6666 0.666626ZM18.9999 24.6666H2.99992V3.33329H12.3333V9.99996H18.9999V24.6666Z"
        fill="black"
      />
      <path
        d="M5.6665 18H9.6665V23.3333H12.3332V18H16.3332L11.0132 12.6666L5.6665 18Z"
        fill="black"
      />
    </svg>
  );
};

export default SvgFileUpload;
