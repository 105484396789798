/** @jsx jsx */

import { Marker, InfoWindow } from "@react-google-maps/api";
import React, { FC, useState } from "react";
import { jsx } from "theme-ui";
import { MarkerWithInfoProps } from "./types";

import { PinIcon } from "@swvl/icons";
const MarkerWithInfo: FC<MarkerWithInfoProps> = ({
  stations,
  currentStation,
  renderStationLabel,
  showStationPopover,
  icon,
}) => {
  const [stationHovered, setStationHovered] = useState(false);
  const [stationSelected, setStationSelected] = useState(false);

  const stationLabel =
    renderStationLabel &&
    renderStationLabel({
      currentStation,
      stations,
    });

  const { label, lat, lng, name } = currentStation;
  const showPopover = showStationPopover && (stationSelected || stationHovered);
  return (
    <Marker
      onClick={() => setStationSelected?.(!stationSelected)}
      onMouseOver={() => setStationHovered?.(true)}
      onMouseOut={() => setStationHovered?.(false)}
      label={label}
      icon={icon}
      position={{ lat, lng }}
      zIndex={2000}
    >
      {stationLabel ? <InfoWindow>{stationLabel}</InfoWindow> : null}

      {showPopover ? (
        <InfoWindow>
          <div style={{ color: "white", width: 200, display: "flex", gap: 8 }}>
            <span>
              <PinIcon width={24} height={24} />
            </span>
            <span>{name}</span>
          </div>
        </InfoWindow>
      ) : null}
    </Marker>
  );
};

export default MarkerWithInfo;
