import * as React from "react";

const SvgMoreHorizontal = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M3 12a2 2 0 114.001.001A2 2 0 013 12zm9-2a2 2 0 10.001 4.001A2 2 0 0012 10zm7 0a2 2 0 10.001 4.001A2 2 0 0019 10z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgMoreHorizontal;
