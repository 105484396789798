import * as React from "react";

const SvgTimer = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M15 12h-2v-2a1 1 0 10-2 0v3a1 1 0 001 1h3a1 1 0 100-2zm-3 7.75A6.758 6.758 0 015.25 13 6.758 6.758 0 0112 6.25 6.758 6.758 0 0118.75 13 6.758 6.758 0 0112 19.75m.988-15.692C12.99 4.037 13 4.021 13 4V3h1a1 1 0 100-2h-4a1 1 0 100 2h1v1c0 .021.01.037.012.058C6.513 4.552 3 8.372 3 13c0 4.963 4.038 9 9 9s9-4.037 9-9c0-4.628-3.513-8.448-8.012-8.942"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgTimer;
