import * as React from "react";

const SvgCaptainReassign = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M5.05 7.38A4 4 0 1013 8a4.213 4.213 0 00-.06-.62c1.26-.46 2.05-1.13 2.05-1.88 0-1.38-2.69-2.5-6-2.5s-6 1.12-6 2.5c.01.75.8 1.42 2.06 1.88zm4 2.62a2 2 0 01-2-2 .61.61 0 010-.14c.662.095 1.33.141 2 .14a13.91 13.91 0 002-.14.607.607 0 010 .14A2 2 0 019 10h.05zM5.94 5.43A9.89 9.89 0 019 5a10.23 10.23 0 013.07.43l.08.11A9.75 9.75 0 019 6a9.8 9.8 0 01-3.15-.46l.09-.11zm14.57 1.03l-1.2 2a5.65 5.65 0 011.85 7.58 5.51 5.51 0 01-1.43 1.61.74.74 0 01-1.14-.38 1.38 1.38 0 01.36-1.42c.237-.222.44-.478.6-.76a3.79 3.79 0 00-1.2-5l-1.18 2-1.23-4.53 4.57-1.1zM4.05 16.05A7 7 0 0116 21h-2a5 5 0 10-10 0H2a7 7 0 012.05-4.95zM8.16 19h1.67a1 1 0 010 2H8.16a1 1 0 010-2z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgCaptainReassign;
