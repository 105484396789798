import * as React from "react";

const SvgLine = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="7" height="1" viewBox="0 0 7 1" fill="none" {...props}>
      <line y1="0.5" x2="7" y2="0.5" stroke="black" />
    </svg>
  );
};

export default SvgLine;
