import * as React from "react";

const SvgWarningFilled = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={24} height={24} fill="white" fillOpacity={0.01} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7664 3.98276C13.0093 4.08343 13.2301 4.23099 13.416 4.41701L19.584 10.584C19.7702 10.7699 19.9179 10.9907 20.0186 11.2338C20.1194 11.4769 20.1713 11.7374 20.1713 12.0005C20.1713 12.2636 20.1194 12.5242 20.0186 12.7672C19.9179 13.0103 19.7702 13.2311 19.584 13.417L13.416 19.584C13.2301 19.77 13.0093 19.9176 12.7664 20.0183C12.5234 20.1189 12.263 20.1707 12 20.1707C11.737 20.1707 11.4766 20.1189 11.2336 20.0183C10.9907 19.9176 10.7699 19.77 10.584 19.584L4.41601 13.417C4.22985 13.2311 4.08216 13.0103 3.9814 12.7672C3.88063 12.5242 3.82877 12.2636 3.82877 12.0005C3.82877 11.7374 3.88063 11.4769 3.9814 11.2338C4.08216 10.9907 4.22985 10.7699 4.41601 10.584L10.584 4.41701C10.7699 4.23099 10.9907 4.08343 11.2336 3.98276C11.4766 3.88208 11.737 3.83026 12 3.83026C12.263 3.83026 12.5234 3.88208 12.7664 3.98276ZM11.2929 13.7071C11.4804 13.8946 11.7348 14 12 14C12.2652 14 12.5196 13.8946 12.7071 13.7071C12.8947 13.5196 13 13.2652 13 13V8C13 7.73478 12.8947 7.48043 12.7071 7.29289C12.5196 7.10536 12.2652 7 12 7C11.7348 7 11.4804 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478 11 8V13C11 13.2652 11.1054 13.5196 11.2929 13.7071ZM11.2929 16.7071C11.4804 16.8946 11.7348 17 12 17C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8947 16.5196 13 16.2652 13 16C13 15.7348 12.8947 15.4804 12.7071 15.2929C12.5196 15.1054 12.2652 15 12 15C11.7348 15 11.4804 15.1054 11.2929 15.2929C11.1054 15.4804 11 15.7348 11 16C11 16.2652 11.1054 16.5196 11.2929 16.7071Z"
        fill="#DA3B07"
      />
    </svg>
  );
};

export default SvgWarningFilled;
