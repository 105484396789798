import isEmpty from "lodash.isempty";
import { useEffect, useRef, useCallback } from "react";
import theme from "@swvl/theme";
import polyline, { MapsEventListener } from "google-polyline";
import first from "lodash/fp/first";
import { Routes, Route, RoutePaths } from "./types";
import { interpolateColors } from "./utils";

const usePath = ({ routes, selectedRouteId, dimmedColorForRoutes, map, disableFitBounds }) => {
  //== each route plotted on map will consist of array of polylines we will call them (paths)
  const paths = useRef<RoutePaths>({});
  //== each polyline/path can have an event listener for click -hover(mouseover) - double click
  const pathsListeners = useRef<{
    [id: string]: MapsEventListener[];
  }>({});

  const addPathEvents = (id: string, route: Route, path: google.maps.Polyline) => {
    const { onClick, onDblClick, onMouseOver, onMouseOut, onMouseUp, onMouseDown } = route;
    if (onClick) {
      const listener = window.google.maps.event.addListener(path, "click", onClick);
      if (pathsListeners.current[id]) {
        pathsListeners.current[id].push(listener);
      } else {
        pathsListeners.current[id] = [listener];
      }
    }

    if (onDblClick) {
      const listener = window.google.maps.event.addListener(path, "dblclick", onDblClick);
      if (pathsListeners.current[id]) {
        pathsListeners.current[id].push(listener);
      } else {
        pathsListeners.current[id] = [listener];
      }
    }

    if (onMouseOver) {
      const listener = window.google.maps.event.addListener(path, "mouseover", onMouseOver);
      if (pathsListeners.current[id]) {
        pathsListeners.current[id].push(listener);
      } else {
        pathsListeners.current[id] = [listener];
      }
    }

    if (onMouseOut) {
      const listener = window.google.maps.event.addListener(path, "mouseout", onMouseOut);
      if (pathsListeners.current[id]) {
        pathsListeners.current[id].push(listener);
      } else {
        pathsListeners.current[id] = [listener];
      }
    }

    if (onMouseUp) {
      const listener = window.google.maps.event.addListener(path, "mouseup", onMouseUp);
      if (pathsListeners.current[id]) {
        pathsListeners.current[id].push(listener);
      } else {
        pathsListeners.current[id] = [listener];
      }
    }

    if (onMouseDown) {
      const listener = window.google.maps.event.addListener(path, "mousedown", onMouseDown);
      if (pathsListeners.current[id]) {
        pathsListeners.current[id].push(listener);
      } else {
        pathsListeners.current[id] = [listener];
      }
    }
  };

  const createRoutePaths = (routes: Routes, selectedRouteId: string) => {
    for (const [id, route] of Object.entries(routes)) {
      // == clear each of the old paths before creating new ones
      clearSingleRoutePath(id);

      const {
        encodedPolyline = "",
        strokeWeight = 3,
        strokeOpacity = 1,
        icons,
        onClick,
        onMouseOver,
        onDblClick,
        color,
        startColor,
        endColor,
      } = route;

      let strokeColor = color;
      //== zIndex is used in the case selecting a particular route
      //== to make it above all other routes in the z plan
      let zIndex = 100;
      if (selectedRouteId && id !== selectedRouteId) {
        strokeColor = dimmedColorForRoutes;
        zIndex = 10;
      }

      const isClickable = !!(onClick || onMouseOver || onDblClick);

      const polylineDecoded = polyline.decode(encodedPolyline);
      let gradientColors;
      if (!strokeColor) {
        // == interpolateColors is used to make an array of colors that can be interpolated into a gradient
        gradientColors = interpolateColors(
          startColor || theme.colors.primary,
          endColor || theme.colors.secondary,
          polylineDecoded.length
        ).map((color) => `rgb(${color.join(",")})`);
      }
      const [firstPointLat, firstPointLng] = first(polylineDecoded) || [];
      let previousLatLang = new window.google.maps.LatLng(firstPointLat, firstPointLng);

      // eslint-disable-next-line no-loop-func
      polylineDecoded.forEach((point, i) => {
        const [lat, lng] = point;
        const currentLatLang = new window.google.maps.LatLng(lat, lng);
        const path = new window.google.maps.Polyline({
          clickable: isClickable,
          path: [previousLatLang, currentLatLang],
          geodesic: true,
          strokeColor: strokeColor || gradientColors[i],
          strokeWeight,
          strokeOpacity,
          zIndex,
          icons,
        });

        //== add all event listeners to the current path
        addPathEvents(id, route, path);

        previousLatLang = currentLatLang;
        if (paths.current[id]) {
          paths.current[id].push(path);
        } else {
          paths.current[id] = [path];
        }
      });
    }
  };

  const fitBounds = (routePaths: RoutePaths) => {
    const bounds = new window.google.maps.LatLngBounds();
    for (const [, route] of Object.entries(routePaths)) {
      route.forEach((path) => {
        path.setMap(map);
        const points = path.getPath().getArray();
        for (let n = 0; n < points.length; n++) {
          bounds.extend(points[n]);
        }
      });
    }
    map?.fitBounds(bounds);
  };

  const setPathsMap = (routePaths: RoutePaths) => {
    for (const [, route] of Object.entries(routePaths)) {
      route.forEach((path) => {
        path.setMap(map);
      });
    }
  };

  const drawAllRoutesPaths = useCallback(() => {
    const routePaths = paths.current;
    if (disableFitBounds) {
      setPathsMap(routePaths);
    } else {
      fitBounds(routePaths);
    }
  }, [paths, map]);

  const clearRoutePaths = useCallback(() => {
    // == clear route paths
    const routePaths = paths.current;
    for (const [id, route] of Object.entries(routePaths)) {
      route.forEach((path) => path.setMap(null));
      delete paths.current[id];
    }

    // == clear event listeners on every path if there's any
    const routeListeners = pathsListeners.current;
    for (const [id, listeners] of Object.entries(routeListeners)) {
      listeners.forEach((listener) => {
        window.google.maps.event.removeListener(listener);
        delete pathsListeners.current[id];
      });
    }
  }, [paths, pathsListeners]);

  const clearSingleRoutePath = useCallback(
    (id: string) => {
      // == clear route paths
      const routePaths = paths.current[id] ?? [];
      routePaths.forEach((path) => path.setMap(null));
      delete paths.current[id];

      // == clear event listeners on every path if there's any
      const listeners = pathsListeners[id] ?? [];
      listeners.forEach((listener) => window.google.maps.event.removeListener(listener));
      delete pathsListeners[id];
    },
    [paths, pathsListeners]
  );

  useEffect(() => {
    if (map && !isEmpty(routes)) {
      createRoutePaths(routes, selectedRouteId);
      drawAllRoutesPaths();
    }
    return clearRoutePaths;
  }, [routes, map, selectedRouteId]);
};

export default usePath;
