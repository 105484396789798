import * as React from "react";

const SvgArrowRight = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M5 13h11.865l-3.633 4.36a1 1 0 101.537 1.28l5-6c.039-.047.058-.102.087-.154.024-.042.053-.078.071-.124a.985.985 0 00.072-.358L20 12l-.001-.004a.985.985 0 00-.072-.358c-.018-.046-.047-.082-.071-.124-.029-.052-.048-.107-.087-.154l-5-6a1.002 1.002 0 00-1.409-.128 1 1 0 00-.128 1.408L16.865 11H5a1 1 0 000 2"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgArrowRight;
