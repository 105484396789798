import * as React from "react";

const SvgArrowSDown = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M12 16a.993.993 0 01-.64-.232l-6-5a1 1 0 111.28-1.536l5.371 4.476 5.362-4.315a1 1 0 011.254 1.558l-6 4.828A1 1 0 0112 16"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgArrowSDown;
