import * as React from "react";

const SvgChevronLeft = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M13.362 17a.996.996 0 01-.719-.305l-3.863-4a1 1 0 01.013-1.402l4-4a.999.999 0 111.414 1.414l-3.305 3.305 3.18 3.293a1 1 0 01-.72 1.695"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SvgChevronLeft;
