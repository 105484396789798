import * as React from "react";

const SvgExternalLink = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8888 12C15.8888 11.6933 16.1372 11.4444 16.4444 11.4444C16.7516 11.4444 17 11.6933 17 12V15.3333C17 16.2522 16.2522 17 15.3333 17H8.66666C7.74777 17 7 16.2522 7 15.3333V8.66666C7 7.74777 7.74777 7 8.66666 7H12C12.3072 7 12.5555 7.24889 12.5555 7.55555C12.5555 7.86222 12.3072 8.11111 12 8.11111H8.66666C8.35999 8.11111 8.11111 8.36055 8.11111 8.66666V15.3333C8.11111 15.6394 8.35999 15.8888 8.66666 15.8888H15.3333C15.64 15.8888 15.8888 15.6394 15.8888 15.3333V12ZM15.0987 8.1156L14.2193 8.11116C13.9121 8.10949 13.6649 7.85949 13.6665 7.55283C13.6682 7.24672 13.9165 7.00005 14.2221 7.00005H14.2249L16.4454 7.01116C16.751 7.01283 16.9982 7.26061 16.9982 7.56616L16.9998 9.77726C16.9998 10.0845 16.7515 10.3334 16.4448 10.3334H16.4443C16.1376 10.3334 15.8887 10.085 15.8887 9.77837L15.8882 8.89726L12.3926 12.3928C12.2843 12.5011 12.1421 12.5556 11.9999 12.5556C11.8576 12.5556 11.7154 12.5011 11.6071 12.3928C11.3899 12.1756 11.3899 11.8245 11.6071 11.6073L15.0987 8.1156Z"
      />
      <mask id="mask0_7547_28313" maskUnits="userSpaceOnUse" x="7" y="7" width="10" height="10">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.8888 12C15.8888 11.6933 16.1372 11.4444 16.4444 11.4444C16.7516 11.4444 17 11.6933 17 12V15.3333C17 16.2522 16.2522 17 15.3333 17H8.66666C7.74777 17 7 16.2522 7 15.3333V8.66666C7 7.74777 7.74777 7 8.66666 7H12C12.3072 7 12.5555 7.24889 12.5555 7.55555C12.5555 7.86222 12.3072 8.11111 12 8.11111H8.66666C8.35999 8.11111 8.11111 8.36055 8.11111 8.66666V15.3333C8.11111 15.6394 8.35999 15.8888 8.66666 15.8888H15.3333C15.64 15.8888 15.8888 15.6394 15.8888 15.3333V12ZM15.0987 8.1156L14.2193 8.11116C13.9121 8.10949 13.6649 7.85949 13.6665 7.55283C13.6682 7.24672 13.9165 7.00005 14.2221 7.00005H14.2249L16.4454 7.01116C16.751 7.01283 16.9982 7.26061 16.9982 7.56616L16.9998 9.77726C16.9998 10.0845 16.7515 10.3334 16.4448 10.3334H16.4443C16.1376 10.3334 15.8887 10.085 15.8887 9.77837L15.8882 8.89726L12.3926 12.3928C12.2843 12.5011 12.1421 12.5556 11.9999 12.5556C11.8576 12.5556 11.7154 12.5011 11.6071 12.3928C11.3899 12.1756 11.3899 11.8245 11.6071 11.6073L15.0987 8.1156Z"
          fill="currentColor"
        />
      </mask>
      <g mask="url(#mask0_7547_28313)">
        <rect width="24" height="24" fill="currentColor" />
      </g>
    </svg>
  );
};

export default SvgExternalLink;
